'use client'

import { Cell } from '@vinted/web-ui'

import { useSession } from '@marketplace-web/shared/session'
import { isDateInTheFuture } from '@marketplace-web/shared/utils'

import FollowButton from 'components/FollowButton'

import { ItemPageUserInfoActionsPluginModel } from './types'

type Props = {
  data: ItemPageUserInfoActionsPluginModel
}

const ItemPageUserInfoActionsPlugin = ({ data }: Props) => {
  const { user: currentUser } = useSession()

  const { user } = data

  if (!user) return null
  if (!currentUser || currentUser.id === user.id) return null
  if (isDateInTheFuture(user.closet_countdown_end_date)) return null

  return (
    <Cell>
      <FollowButton userId={user.id} isActive={user.is_favourite} isFlat />
    </Cell>
  )
}

export default ItemPageUserInfoActionsPlugin
