'use client'

import { Button, Cell, Dialog, Spacer, Text } from '@vinted/web-ui'

import { SELLING_HELP_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { clickEvent } from '_libs/common/event-tracker/events'

type Props = {
  show: boolean
  onClose: () => void
}

const ProcessingListingStatusModal = ({ show, onClose }: Props) => {
  const translate = useTranslate('processing_listing.modal')
  const { track } = useTracking()

  const handleFaqLinkClick = () => {
    track(
      clickEvent({
        target: ClickableElement.ProcessingListingStatusModalFaqLink,
        screen: Screen.ProcessingListingStatusModal,
      }),
    )
  }

  return (
    <Dialog show={show} testId="processing-listing-modal">
      <Cell>
        <Text
          as="h1"
          text={translate('title')}
          alignment={Text.Alignment.Left}
          type={Text.Type.Heading}
        />
        <Spacer size={Spacer.Size.Large} />
        <Text
          as="span"
          text={translate('body', {
            'catalog-rules-link': chunks => (
              <a
                onClick={handleFaqLinkClick}
                href={SELLING_HELP_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                {chunks}
              </a>
            ),
          })}
          html
        />
        <Spacer size={Spacer.Size.Large} />
        <Button
          text={translate('actions.close')}
          onClick={onClose}
          styling={Button.Styling.Filled}
          testId="processing-listing-modal-close-button"
        />
      </Cell>
    </Dialog>
  )
}

export default ProcessingListingStatusModal
