import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageClosetTitlePluginModel } from './types'

export const getItemPageClosetTitlePluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageClosetTitlePluginModel => ({
  itemCount: itemDto.user.item_count,
  itemUserId: itemDto.user.id,
})
