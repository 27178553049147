import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageAskSellerActionPluginModel } from './types'

export const getItemPageAskSellerActionPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageAskSellerActionPluginModel => ({
  itemId: itemDto.id,
  itemUserId: itemDto.user.id,
  canBuy: itemDto.can_buy,
  canInstantBuy: itemDto.instant_buy,
  isClosed: itemDto.is_closed,
  isHidden: itemDto.is_hidden,
  isReserved: itemDto.is_reserved,
  reservedForUserId: itemDto.reservation?.to_whom_id,
})
