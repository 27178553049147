'use client'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { urlWithParams } from '@marketplace-web/shared/utils'
import { clickEvent } from '_libs/common/event-tracker/events'
import { ROOT_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import BreadcrumbsComponent from './Breadcrumbs'
import { ItemBreadcrumbDto, ItemPageBreadcrumbsPluginModel } from './types'

const getUrlWithParams = (url: string) => {
  if (url === ROOT_URL) return url

  return urlWithParams(url, { referrer: 'item-crumbs' })
}

const ItemPageBreadcrumbsPlugin = (breadcrumbsData: ItemPageBreadcrumbsPluginModel) => {
  const { track } = useTracking()

  const trackBreadcrumbClick = (breadcrumb: ItemBreadcrumbDto, position: number) => () => {
    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.Breadcrumb,
        targetDetails: JSON.stringify({
          position: position + 1,
          breadcrumb_title: breadcrumb.title,
        }),
      }),
    )
  }

  return (
    <div className="u-ui-margin-top-medium">
      <BreadcrumbsComponent truncate>
        {breadcrumbsData.breadcrumbs.map((breadcrumb, index) => (
          <BreadcrumbsComponent.Item
            key={breadcrumb.title}
            url={getUrlWithParams(breadcrumb.url)}
            title={breadcrumb.title}
            onClick={trackBreadcrumbClick(breadcrumb, index)}
          />
        ))}
      </BreadcrumbsComponent>
    </div>
  )
}

export default ItemPageBreadcrumbsPlugin
