'use client'

import { useState } from 'react'
import { Button } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { useAuthModal } from '@marketplace-web/domain/identity'
import { useConversationContext } from '@marketplace-web/domain/core-conversation'

import { ConversationInitiatorType } from 'constants/conversation'

import { ItemPageMakeOfferActionPluginModel } from '../types'
import ItemBuyerOfferModal from '../ItemBuyerOfferModal/ItemBuyerOfferModal'

type Props = {
  data: ItemPageMakeOfferActionPluginModel
}

export const ItemBuyerOfferModalContainer = ({ data }: Props) => {
  const { itemId, itemTitle, itemThumbnailUrl, price, sellerId, currency, discountedPrice } = data

  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false)

  const translate = useTranslate('item.actions')
  const { openAuthModal } = useAuthModal()
  const { createItemConversationThreadRequest } = useConversationContext()

  const { user } = useSession()
  const isAuthenticated = !!user

  const itemIdStringified = itemId.toString()
  const sellerIdStringified = sellerId.toString()

  const handleOfferModalOpen = () => {
    if (isAuthenticated) {
      setIsOfferModalOpen(true)

      createItemConversationThreadRequest({
        itemId: itemIdStringified,
        sellerId: sellerIdStringified,
        initiator: ConversationInitiatorType.BuyerEntersOfferForm,
      })

      return
    }

    openAuthModal()
  }

  const handleOfferModalClose = () => setIsOfferModalOpen(false)

  return (
    <>
      <Button
        text={translate('offer_request')}
        onClick={handleOfferModalOpen}
        testId="item-buyer-offer-button"
      />
      {isAuthenticated && (
        <ItemBuyerOfferModal
          isOpen={isOfferModalOpen}
          itemInfo={{
            itemId: itemIdStringified,
            itemIds: [itemId],
            itemTitle,
            itemThumbnailUrl,
            itemPrice: discountedPrice?.amount || price,
            sellerId,
          }}
          currency={currency}
          onClose={handleOfferModalClose}
        />
      )}
    </>
  )
}
