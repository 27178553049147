'use client'

import { ExclamationCircleFilled32 } from '@vinted/monochrome-icons'
import { Button, Card, Cell, Icon, Spacer, Text } from '@vinted/web-ui'
import { useState } from 'react'

import { useFetch } from '@marketplace-web/shared/api-client'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { PhotoTips } from '@marketplace-web/shared/photo-tips'

import { ItemAlertStatus } from 'constants/item'
import { EDIT_ITEM_URL_WITH_ANCHOR } from 'constants/routes'
import { getPhotoTip } from 'data/api'
import { transformPhotoTipResponse } from 'data/api/transformers/response'

import { ItemPageAlertsPluginModel } from './types'

type CtaButtonProps = Partial<ComponentProps<typeof Button>> | null

const ItemPageAlertsPlugin = ({ itemId, itemAlert }: ItemPageAlertsPluginModel) => {
  const [isPhotoTipsOpen, setIsPhotoTipsOpen] = useState(false)
  const translate = useTranslate('item.alerts')
  const { fetch: fetchPhotoTip, transformedData: photoTip } = useFetch(
    getPhotoTip,
    transformPhotoTipResponse,
  )

  const closePhotoTips = () => setIsPhotoTipsOpen(false)

  const openPhotoTips = () => {
    if (!itemAlert.photoTipId) return

    setIsPhotoTipsOpen(true)

    if (photoTip) return

    fetchPhotoTip(itemAlert.photoTipId)
  }

  const getCtaButtonProps = (): CtaButtonProps => {
    if (itemAlert.photoTipId)
      return {
        text: translate('actions.learn_more'),
        onClick: openPhotoTips,
      }

    switch (itemAlert.itemAlertType) {
      case ItemAlertStatus.PackageSize: {
        return {
          text: translate('actions.package_size'),
          url: EDIT_ITEM_URL_WITH_ANCHOR(itemId, 'packages-selector'),
        }
      }
      case ItemAlertStatus.MissingSubcategory: {
        return {
          text: translate('actions.missing_subcategory'),
          url: EDIT_ITEM_URL_WITH_ANCHOR(itemId, 'categories'),
        }
      }
      default:
        return null
    }
  }

  const renderCtaButton = () => {
    const ctaButtonProps = getCtaButtonProps()

    if (!ctaButtonProps) return null

    return (
      <div className="u-flexbox u-align-items-center">
        <Spacer orientation={Spacer.Orientation.Vertical} />
        <Button
          {...ctaButtonProps}
          styling={Button.Styling.Filled}
          size={Button.Size.Medium}
          testId="item-alert-cta-button"
        />
      </div>
    )
  }

  const renderDescription = () => {
    if (!itemAlert.detailedDescription) return null

    return (
      <>
        <Card>
          <Cell suffix={renderCtaButton()} testId="item-alert-box">
            <div className="u-flexbox u-align-items-center">
              <Icon name={ExclamationCircleFilled32} color={Icon.Color.Warning} />
              <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Large} />
              <Text
                as="h3"
                text={itemAlert.detailedDescription}
                type={Text.Type.Subtitle}
                theme="amplified"
              />
            </div>
          </Cell>
        </Card>
        <Spacer />
      </>
    )
  }

  return (
    <>
      {renderDescription()}
      {itemAlert.photoTipId && photoTip && (
        <PhotoTips isOpen={isPhotoTipsOpen} photoTips={[photoTip]} handleClose={closePhotoTips} />
      )}
    </>
  )
}

export default ItemPageAlertsPlugin
