'use client'

import { Image } from '@vinted/web-ui'
import { useCallback, useEffect, useState } from 'react'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { ImageCarousel } from '@marketplace-web/shared/ui-helpers'
import { userViewItemGallery } from '_libs/common/event-tracker/events'

import FavouriteButton from './FavouriteButton'
import { ItemPageGalleryPluginModel } from './types'

const RENDER_PHOTOS_LIMIT = 5
const LAST_PHOTO_INDEX = RENDER_PHOTOS_LIMIT - 1

type Props = {
  data: ItemPageGalleryPluginModel
}

type CarouselChangeDirection = Parameters<
  NonNullable<ComponentProps<typeof ImageCarousel>['onImageChange']>
>[1]

const hasCarouselLooped = (index: number, length: number, direction: CarouselChangeDirection) => {
  if (direction === 'right') return index === 0

  return index === length - 1
}

const ItemPageGalleryPlugin = ({ data }: Props) => {
  const { id, title, photos, ownerId, isFavourite, itemId, favouriteCount } = data
  const numberOfPhotos = photos.length
  const visiblePhotos = photos.slice(0, RENDER_PHOTOS_LIMIT)
  const hiddenPhotosCount = numberOfPhotos - RENDER_PHOTOS_LIMIT

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState<number | null>(null)

  const breakpoint = useBreakpoint()
  const { track } = useTracking()

  const trackUserViewItemGallery = useCallback(
    (imagePosition: number, isLoop: boolean) => {
      track(
        userViewItemGallery({
          itemId: id,
          imagePosition: imagePosition + 1,
          isLoop,
          imageCount: visiblePhotos.length,
          contentType: 'gallery_image',
          galleryContainsAd: false,
        }),
      )
    },
    [id, track, visiblePhotos.length],
  )

  useEffect(() => {
    if (selectedPhotoIndex !== null) trackUserViewItemGallery(selectedPhotoIndex, false)
  }, [selectedPhotoIndex, trackUserViewItemGallery])

  const handleCarouselClose = () => setSelectedPhotoIndex(null)
  const handlePhotoClick = (index: number) => setSelectedPhotoIndex(index)

  const handleImageChange = (imageIndex: number, direction: CarouselChangeDirection) => {
    trackUserViewItemGallery(
      imageIndex,
      hasCarouselLooped(imageIndex, visiblePhotos.length, direction),
    )
  }

  return (
    <>
      <section className="item-photos__container">
        <div className="item-photos">
          <FavouriteButton
            isFavourited={isFavourite}
            itemId={itemId}
            initialFavouriteCount={favouriteCount}
            itemUserId={ownerId}
          />
          {visiblePhotos.map((photo, index) => (
            <figure
              className={`item-description u-flexbox item-photo item-photo--${index + 1}`}
              key={photo.id}
            >
              <button
                type="button"
                className="item-thumbnail"
                data-photoid={photo.id}
                onClick={() => handlePhotoClick(index)}
              >
                <Image
                  src={photo.url}
                  scaling={Image.Scaling.Cover}
                  label={
                    !!hiddenPhotosCount && index === LAST_PHOTO_INDEX
                      ? `+ ${hiddenPhotosCount}`
                      : undefined
                  }
                  alt={numberOfPhotos === 1 ? `${title}` : `${title} ${index + 1}`}
                  color={photo.dominant_color_opaque}
                  testId={`item-photo-${index + 1}`}
                  styling={breakpoint.desktops ? Image.Styling.Rounded : undefined}
                />
              </button>
            </figure>
          ))}
        </div>
      </section>
      {selectedPhotoIndex !== null && (
        <ImageCarousel
          imageSources={photos.map(photo => photo.url)}
          initialImageIndex={selectedPhotoIndex}
          onClose={handleCarouselClose}
          onImageChange={handleImageChange}
        />
      )}
    </>
  )
}

export default ItemPageGalleryPlugin
