'use client'

import { useContext } from 'react'
import { Cell, Spacer, Text, Icon } from '@vinted/web-ui'
import { ElectronicsBadgeFilled16 } from '@vinted/monochrome-icons'

import { useTranslate } from '@marketplace-web/shared/i18n'

import { EscrowFeesContext } from 'pages/Item/containers/EscrowFeesProvider'

const ItemPageElectronicsVerificationStatusPlugin = () => {
  const translate = useTranslate('item.status')
  const { escrowFees } = useContext(EscrowFeesContext)
  if (!escrowFees?.electronicsVerification) return null

  return (
    <div className="electronics-verification-status">
      <Cell
        styling={Cell.Styling.Narrow}
        theme="transparent"
        testId="electronics-verification-service-block-status"
      >
        <div className="u-flexbox u-align-items-center">
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Icon
            name={ElectronicsBadgeFilled16}
            color={Icon.Color.Primary}
            aria={{
              'aria-hidden': 'true',
            }}
          />
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Text
            as="h4"
            text={translate('electronics_verification')}
            type={Text.Type.Caption}
            theme="amplified"
          />
        </div>
      </Cell>
    </div>
  )
}

export default ItemPageElectronicsVerificationStatusPlugin
