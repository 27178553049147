'use client'

import { ReactNode } from 'react'
import classNames from 'classnames'

import BreadcrumbsItem from './BreadcrumbsItem'

type Props = {
  testId?: string
  children?: ReactNode
  truncate?: boolean
}

const Breadcrumbs = ({ children, testId, truncate = false }: Props) => {
  if (!children) return null

  const className = classNames('breadcrumbs', { 'breadcrumbs--truncated': truncate })

  return (
    <ul data-testid={testId} className={className}>
      {children}
    </ul>
  )
}

Breadcrumbs.Item = BreadcrumbsItem

export default Breadcrumbs
