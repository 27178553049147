'use client'

import { ItemPageMakeOfferActionPlugin } from '@marketplace-web/domain/price-offers'
import ItemPageBuyButtonPlugin from 'pages/Item/plugins/BuyButton/ItemPageBuyButtonPlugin'
import { ItemDetailsDto } from 'types/dtos/item-details'

import { useItemPlugins } from '../../hooks/useItemPlugins'

type Props = {
  item: ItemDetailsDto
}

const ItemStickyButtons = ({ item }: Props) => {
  const plugins = useItemPlugins(item)

  return (
    <div className="item-sticky-buttons" data-testid="item-sticky-buttons">
      {plugins?.makeOfferAction && (
        <ItemPageMakeOfferActionPlugin isStickyButton data={plugins.makeOfferAction} />
      )}
      {plugins?.buyButton && <ItemPageBuyButtonPlugin isStickyButton data={plugins.buyButton} />}
    </div>
  )
}

export default ItemStickyButtons
