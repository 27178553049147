'use client'

import { Cell, Image, Spacer, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { useAsset } from '@marketplace-web/shared/ui-helpers'

type Props = {
  isTight?: boolean
}

const UserBadge = ({ isTight }: Props) => {
  const translate = useTranslate('user.seller_badges')
  const asset = useAsset('/assets/user-badges')

  return (
    <div className="u-flexbox u-background-white">
      {isTight && <Spacer size={Spacer.Size.Large} orientation={Spacer.Orientation.Vertical} />}
      <Cell
        styling={isTight ? Cell.Styling.Tight : Cell.Styling.Default}
        prefix={
          <Image
            src={asset('lister-badge.svg')}
            alt=""
            size={Image.Size.Medium}
            scaling={Image.Scaling.Contain}
          />
        }
        title={translate('frequent_uploads.title')}
        body={<Text as="h3" text={translate('frequent_uploads.body')} type={Text.Type.Subtitle} />}
      />
    </div>
  )
}

export default UserBadge
