'use client'

import { useRef } from 'react'
import { StarFilled24 } from '@vinted/monochrome-icons'
import { Button, Cell, Icon, Text } from '@vinted/web-ui'
import classNames from 'classnames'
import { InView, useInView } from 'react-intersection-observer'

import { useAbTest, useTrackAbTestCallback } from '@marketplace-web/shared/ab-tests'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { HorizontalScrollArea } from '@marketplace-web/shared/ui-helpers'

import { clickEvent, itemPageViewEvent, viewEvent } from '_libs/common/event-tracker/events'
import { MEMBER_FEEDBACK_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ViewableElement } from 'constants/tracking/viewable-elements'

import { ItemPageSellerFeedbacksPluginModel, SellerFeedbackModel } from './types'
import FeedbackCard from './FeedbackCard'

const ItemPageSellerFeedbacksPlugin = ({
  title,
  cta,
  feedbacks,
  sellerId,
  itemId,
}: ItemPageSellerFeedbacksPluginModel) => {
  const { track } = useTracking()
  const isExposureSeen = useRef(false)

  const buyerHoldoutAbTestEnabled = useAbTest('buyer_domain_holdout_2024q3')?.variant === 'on'
  const sellerReviewsAbTest = useAbTest('item_page_last_6_seller_reviews')
  const trackAbTest = useTrackAbTestCallback()

  const sellerReviewsAbTestEnabled = sellerReviewsAbTest?.variant === 'on'

  const { ref } = useInView({
    onChange: inView => {
      if (!inView) return
      if (!buyerHoldoutAbTestEnabled) return
      if (isExposureSeen.current) return

      if (sellerReviewsAbTest) {
        isExposureSeen.current = true
        trackAbTest(sellerReviewsAbTest)
      }

      if (sellerReviewsAbTestEnabled) {
        track(
          viewEvent({
            screen: Screen.Item,
            target: ViewableElement.SellerFeedbacks,
            targetDetails: JSON.stringify({
              item_id: itemId,
            }),
          }),
        )
      }
    },
  })

  if (!buyerHoldoutAbTestEnabled || !sellerReviewsAbTestEnabled) return <div ref={ref} />

  const getColumnWidth = () => {
    switch (feedbacks.length) {
      case 1:
        return 'full-width'
      case 2:
        return 'half-width'
      default:
        return 'default'
    }
  }

  const navigateToReviews = () => {
    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.SellerFeedbackTitle,
        targetDetails: JSON.stringify({
          owner_id: sellerId.toString(),
        }),
      }),
    )

    navigateToPage(MEMBER_FEEDBACK_URL(sellerId))
  }

  const handleSeeAllClick = () => {
    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.ViewAllFeedbacks,
        targetDetails: JSON.stringify({
          item_id: itemId,
        }),
      }),
    )
  }

  const handleCardView = (feedback: SellerFeedbackModel, position: number) => (inView: boolean) => {
    if (!inView) return

    track(
      itemPageViewEvent({
        target: ViewableElement.Feedback,
        item_id: itemId.toString(),
        details: JSON.stringify({
          feedback_id: feedback.id,
          position,
          feedbacks_count: feedbacks.length,
        }),
      }),
    )
  }

  return (
    <section data-testid="item-page-seller-feedbacks-plugin" ref={ref}>
      <Cell
        prefix={<Icon name={StarFilled24} color={Icon.Color.Expose} />}
        title={<Text text={title} html as="p" theme="amplified" truncate />}
        divider
        type={Cell.Type.Navigating}
        testId="seller-feedback--info-about-seller"
        onClick={navigateToReviews}
      />
      <Cell
        body={
          <HorizontalScrollArea showControls={false}>
            <div
              className={classNames(
                'seller-feedbacks__cards-container',
                `seller-feedbacks__cards-container--${getColumnWidth()}`,
              )}
            >
              {feedbacks.map((feedback, position) => (
                <InView
                  as="div"
                  className="feedback-card-container"
                  key={feedback.id}
                  onChange={handleCardView(feedback, position)}
                >
                  <FeedbackCard feedback={feedback} sellerId={sellerId} itemId={itemId} />
                </InView>
              ))}
            </div>
          </HorizontalScrollArea>
        }
      />
      {cta && (
        <Cell
          body={
            <div className="seller-feedbacks__cta">
              <Button
                text={cta.title}
                size={Button.Size.Medium}
                testId="seller-feedback--cta"
                url={MEMBER_FEEDBACK_URL(sellerId)}
                onClick={handleSeeAllClick}
                truncated
              />
            </div>
          }
        />
      )}
    </section>
  )
}

export default ItemPageSellerFeedbacksPlugin
