'use client'

import { useSession } from '@marketplace-web/shared/session'

import BumpCheckoutProvider from '../../../components/Bumps/containers/BumpCheckoutProvider'
import ItemBumpButton from './ItemBumpButton'

import { ItemPageBumpActionModel } from './types'

type Props = {
  bumpActionPluginModel: ItemPageBumpActionModel | undefined
}

const ItemPageBumpActionPlugin = ({ bumpActionPluginModel }: Props) => {
  const { user: currentUser } = useSession()

  const isViewingOwnItem = bumpActionPluginModel?.userId === currentUser?.id

  if (!bumpActionPluginModel || !isViewingOwnItem) return null

  return (
    <BumpCheckoutProvider>
      <ItemBumpButton item={bumpActionPluginModel} />
    </BumpCheckoutProvider>
  )
}

export default ItemPageBumpActionPlugin
