import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageHideActionModel } from '../types'

export const getItemPageHideActionPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageHideActionModel => ({
  id: itemDto.id,
  isItemHidden: itemDto.is_hidden,
  isTransactionPermitted: !!itemDto.transaction_permitted,
  canEdit: !!itemDto.can_edit,
  userId: itemDto.user.id,
})
