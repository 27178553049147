import { ItemThumbnailSize } from '@marketplace-web/shared/utils'
import { transformCurrencyAmountDto } from 'data/transformers/currency-amount'
import { transformIconBadges } from 'data/transformers/icon-badges'
import { transformItemPhotoDtos } from 'data/transformers/item'
import { transformItemBoxDto } from 'data/transformers/item-box'
import {
  getDominantColor,
  transformPartialUserDtoToProductItemUser,
} from 'data/transformers/product-item'
import { getItemThumbnail } from 'data/utils/item'
import { ItemsBlockItemDto } from 'types/dtos/item-page-items-block'
import { ItemSearchParamsDto } from 'types/dtos/item-search'
import { ProductItemModel } from 'types/models/product-item'

import {
  ItemPageItemsBlockDto,
  ItemPageItemsBlockHeaderDto,
  ItemPageItemsBlockHeaderPluginModel,
  ItemPageItemsBlockPluginModel,
} from './types'

export const transformItemsBlockItemDtoToProductItem = (
  item: ItemsBlockItemDto,
): ProductItemModel & {
  searchTrackingParams?: ItemSearchParamsDto | null
} => ({
  id: item.id,
  title: item.title,
  url: item.url,
  photos: item.photo ? transformItemPhotoDtos([item.photo]) : [],
  viewCount: item.view_count,
  favouriteCount: item.favourite_count,
  user: transformPartialUserDtoToProductItemUser(item.user),
  price: transformCurrencyAmountDto(item.price),
  thumbnailUrl: item.photo ? getItemThumbnail([item.photo], ItemThumbnailSize.Large) : null,
  dominantColor: item.photo ? getDominantColor([item.photo]) : null,
  badge: item.badge,
  isPromoted: item.promoted,
  isDraft: item.is_draft,
  isFavourite: item.is_favourite,
  iconBadges: transformIconBadges(item.icon_badges),
  searchTrackingParams: item.search_tracking_params,
  priceWithDiscount: item.discount_price ? transformCurrencyAmountDto(item.discount_price) : null,
  serviceFee: item.service_fee ? transformCurrencyAmountDto(item.service_fee) : null,
  totalItemPrice: item.total_item_price
    ? transformCurrencyAmountDto(item.total_item_price)
    : undefined,
  itemBox: item.item_box && transformItemBoxDto(item.item_box),
})

export const getItemPageItemsBlockPluginModel = (
  pluginData: ItemPageItemsBlockDto,
  horizontalScrollEnabled: boolean,
): ItemPageItemsBlockPluginModel => ({
  itemId: pluginData.item_id,
  contentSource: pluginData.content_source,
  items: pluginData.items ? pluginData.items.map(transformItemsBlockItemDtoToProductItem) : null,
  horizontalScrollEnabled,
})

export const getItemPageItemsBlockHeaderPluginModel = (
  pluginData: ItemPageItemsBlockHeaderDto,
): ItemPageItemsBlockHeaderPluginModel => {
  const {
    header_cta: headerCta,
    item_id: itemId,
    title,
    content_source: contentSource,
  } = pluginData

  return {
    itemId,
    title,
    contentSource,
    headerCta: headerCta ? { title: headerCta.title, url: headerCta.url } : null,
  }
}
