'use client'

import { ReactNode, useState } from 'react'

import { Button, Cell, Divider, Dialog, Text } from '@vinted/web-ui'

import { deleteItem } from 'data/api'
import { navigateToPage } from '@marketplace-web/shared/browser'
import { clickEvent } from '_libs/common/event-tracker/events'
import { MEMBER_PROFILE_URL, TERMS_URL } from 'constants/routes'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { ItemPageDeleteActionModel } from './types'

type Props = {
  deleteActionPluginModel: ItemPageDeleteActionModel | undefined
}

const ItemPageDeleteActionPlugin = ({ deleteActionPluginModel }: Props) => {
  const userId = useSession().user?.id
  const { track } = useTracking()
  const translate = useTranslate('item')
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  if (!deleteActionPluginModel) return null

  const { id: itemId, canDelete, isClosed, userId: itemUserId } = deleteActionPluginModel
  const isViewingOwnItem = itemUserId === userId

  const handleButtonClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
    if (isLoading) {
      event.preventDefault()

      return
    }

    track(
      clickEvent({
        target: ClickableElement.DeleteItem,
        screen: Screen.Item,
        targetDetails: JSON.stringify({
          item_id: itemId,
          user_id: itemUserId,
        }),
      }),
    )

    setShowModal(true)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  const handleModalConfirm = async () => {
    if (!userId) return

    setIsLoading(true)
    setShowModal(false)

    await deleteItem(itemId)

    navigateToPage(MEMBER_PROFILE_URL(userId))
  }

  if (!isViewingOwnItem) return null
  if (!canDelete || !userId) return null

  const translationValues = {
    'terms-link': (chunks: Array<ReactNode>) => (
      <a href={TERMS_URL} target="_blank" rel="noreferrer">
        {chunks}
      </a>
    ),
  }

  const renderItemDeletionConfirmationBrowserModal = () => {
    if (!showModal) return
    // eslint-disable-next-line no-alert
    if (window.confirm(translate('deletion_modal.confirm'))) {
      handleModalConfirm()
    }
  }

  const renderItemDeletionConfirmationModal = () => (
    <Dialog
      show={showModal}
      defaultCallback={handleModalClose}
      testId="item-delete-modal"
      contentDimensions={{ maxWidth: '360px' }}
    >
      <Cell>
        <Text
          as="h2"
          type={Text.Type.Title}
          alignment={Text.Alignment.Center}
          width={Text.Width.Parent}
          text={translate('deletion_modal.title')}
        />
      </Cell>
      <Divider />
      <Cell>
        <Text
          as="h3"
          type={Text.Type.Subtitle}
          bold
          text={translate('deletion_modal.body', translationValues)}
          html
        />
      </Cell>
      <Divider />
      <div className="u-flexbox">
        <Button
          onClick={handleModalClose}
          styling={Button.Styling.Flat}
          theme="muted"
          text={translate('deletion_modal.actions.cancel')}
          testId="item-delete-cancelation-button"
        />
        <Divider orientation={Divider.Orientation.Vertical} />
        <Button
          onClick={handleModalConfirm}
          styling={Button.Styling.Flat}
          theme="warning"
          text={translate('deletion_modal.actions.delete')}
          testId="item-delete-confirmation-button"
        />
      </div>
    </Dialog>
  )

  return (
    <>
      <Button
        text={translate('actions.delete')}
        size={Button.Size.Medium}
        onClick={handleButtonClick}
        theme="warning"
        isLoading={isLoading}
        disabled={isLoading}
        testId="item-delete-button"
      />
      {isClosed
        ? renderItemDeletionConfirmationBrowserModal()
        : renderItemDeletionConfirmationModal()}
    </>
  )
}

export default ItemPageDeleteActionPlugin
