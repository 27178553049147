import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageItemStatusPluginModel } from './types'

export const getItemPageItemStatusPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageItemStatusPluginModel => ({
  userId: itemDto.user.id,
  reservation: itemDto.reservation,
  isDraft: itemDto.is_draft,
  isReserved: itemDto.is_reserved,
  isHidden: itemDto.is_hidden,
  isClosed: itemDto.is_closed,
  itemClosingAction: itemDto.item_closing_action,
  statsVisible: itemDto.stats_visible,
  itemAlert: itemDto.item_alert,
  isProcessing: itemDto.is_processing,
  promoted: itemDto.promoted,
})
