import { transformPhotoDto } from 'data/transformers/photo'

import {
  ItemPageSellerFeedbacksDto,
  ItemPageSellerFeedbacksPluginModel,
  SellerFeedbackDto,
  SellerFeedbackModel,
} from './types'

const transformFeedbacks = (feedbacks: Array<SellerFeedbackDto>): Array<SellerFeedbackModel> => {
  return feedbacks.map(feedback => ({
    id: feedback.id,
    createdAt: feedback.created_at,
    feedback: feedback.feedback,
    rating: feedback.rating,
    user: {
      id: feedback.user.id,
      login: feedback.user.login,
      photo: feedback.user.photo ? transformPhotoDto(feedback.user.photo) : null,
    },
    localization: feedback.localization,
  }))
}

export const getItemPageSellerFeedbacksPluginModel = (
  sellerFeedbacksDto: ItemPageSellerFeedbacksDto,
): ItemPageSellerFeedbacksPluginModel => {
  return {
    title: sellerFeedbacksDto.title,
    cta: sellerFeedbacksDto.cta,
    sellerId: sellerFeedbacksDto.seller_id,
    feedbacks: transformFeedbacks(sellerFeedbacksDto.feedbacks),
    itemId: sellerFeedbacksDto.item_id,
  }
}
