'use client'

import { useEffect, useRef } from 'react'

import { AdShape, Advertisement } from '@marketplace-web/domain/ads'
import { ItemStickyButtons, useItemPlugins } from '@marketplace-web/domain/item-page'
import {
  shouldTrackOncePerSessionDay,
  useAbTest,
  useTrackAbTest,
} from '@marketplace-web/shared/ab-tests'
import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useSession } from '@marketplace-web/shared/session'
import { ErrorBoundary } from '@marketplace-web/shared/ui-helpers'

import { viewItemEvent, viewOwnItemEvent } from '_libs/common/event-tracker/events'
import { extractItemIdFromUrl } from '_libs/utils/item'
import { getSearchSessionData } from '_libs/utils/search'
import { ItemPagePluginEntityDto } from 'types/dtos'
import { ItemDetailsDto } from 'types/dtos/item-details'
import { EscrowFeesModel } from 'types/models/escrow-fees'
import { ItemShippingDetailsModel } from 'types/models/shipping-option'

import ErrorModal from './common/ErrorModal'
import EscrowFeesProvider from './containers/EscrowFeesProvider'
import PluginsProvider from './containers/PluginsProvider'
import ShippingDetailsProvider from './containers/ShippingDetailsProvider'
import ContentSection from './ContentSection'
import Feed from './Feed'
import Plugin from './plugins/Plugin'
import Sidebar from './Sidebar'
import { PluginName } from './types'

import {
  incrementPageLoadCounter,
  logItemPageError,
  observePageLoadTime,
} from './utils/observability'
import { getReferrerPath } from './utils/url'

type Props = {
  itemDto: ItemDetailsDto
  shippingDetails?: ItemShippingDetailsModel | null
  escrowFees?: EscrowFeesModel | null
  plugins?: Array<ItemPagePluginEntityDto>
}

const Item = ({ itemDto, shippingDetails, plugins: serverSidePlugins, escrowFees }: Props) => {
  const pageLoadStartTime = useRef<number>(performance.now())
  const isLoadTimeObserved = useRef(false)

  const { track } = useTracking()
  const { relativeUrl } = useLocation()
  const breakpoint = useBreakpoint()
  const plugins = useItemPlugins(itemDto)

  const isHoldoutEnabled = useAbTest('buyer_domain_holdout_2025q1')?.variant === 'on'
  const adRemovalAbTest = useAbTest('web_ad_removal_itempage')

  const buyerHoldoutAbTestEnabled = useAbTest('buyer_domain_holdout_2025q1')?.variant === 'on'
  const hideAdItempageAbTest = useAbTest('web_ad_removal_itempage')?.variant === 'on'

  const hideAdItempageAbTestEnabled = buyerHoldoutAbTestEnabled && hideAdItempageAbTest

  useTrackAbTest(useAbTest('dd_tx_long_text_behavior_web'), shouldTrackOncePerSessionDay)
  useTrackAbTest(adRemovalAbTest, isHoldoutEnabled && shouldTrackOncePerSessionDay)

  const currentUserId = useSession().user?.id

  const itemId = extractItemIdFromUrl(relativeUrl) || itemDto?.id
  const { searchSessionId, globalCatalogBrowseSessionId } = getSearchSessionData()

  const holidayModeRedirectAbTest = useAbTest('holiday_mode_redirect')

  useEffect(() => {
    incrementPageLoadCounter('initiated')
  }, [])

  useTrackAbTest(
    holidayModeRedirectAbTest,
    holidayModeRedirectAbTest?.variant === 'off' && itemDto?.user.is_on_holiday,
  )

  useEffect(() => {
    if (!itemDto?.id) return

    const referrerPath = getReferrerPath()

    if (currentUserId === itemDto?.user.id) {
      track(
        viewOwnItemEvent({
          referrerPath,
          searchSessionId,
          itemId: itemDto.id,
          ownerId: itemDto.user.id,
        }),
      )

      return
    }

    track(
      viewItemEvent({
        referrerPath,
        searchSessionId,
        globalCatalogBrowseSessionId,
        itemId: itemDto.id,
        ownerId: itemDto.user.id,
      }),
    )
  }, [
    track,
    currentUserId,
    searchSessionId,
    globalCatalogBrowseSessionId,
    itemDto?.id,
    itemDto?.user.id,
  ])

  useEffect(() => {
    if (isLoadTimeObserved.current) return

    const observeLoadEnd = (state: 'succeeded' | 'failed') => {
      const itemLoadTime = performance.now() - pageLoadStartTime.current

      observePageLoadTime(state, itemLoadTime)
      incrementPageLoadCounter(state)

      isLoadTimeObserved.current = true
    }

    if (plugins && Object.values(plugins).every(plugin => plugin !== null)) {
      observeLoadEnd('succeeded')
    } else {
      observeLoadEnd('failed')
    }
  }, [plugins])

  const renderContent = () => {
    if (!itemDto) return null

    const feedProps: ComponentProps<typeof Feed> = {
      closetData: plugins?.closet ?? undefined,
      similarItemsData: plugins?.similarItems ?? undefined,
      shopBundlesBlockData: plugins?.shopBundlesBlock ?? undefined,
      closetTitleData: plugins?.closetTitle ?? undefined,
    }
    const contentProps: ComponentProps<typeof ContentSection> = {
      galleryPluginModel: plugins?.gallery ?? undefined,
      reportButtonPluginModel: plugins?.reportButton ?? undefined,
    }

    return (
      <>
        {!hideAdItempageAbTestEnabled && (
          <Advertisement shape={AdShape.Leaderboard} id="ad-leaderboard" />
        )}
        <main className="item-information">
          <Plugin
            data={serverSidePlugins?.find(plugin => plugin.name === PluginName.AdminSection)}
          />

          {breakpoint.portables && (
            <section
              id="content-mobiles"
              className="item-page-content-container"
              data-testid="item-mobiles-only-container"
            >
              <ContentSection {...contentProps} />
              <Sidebar item={itemDto} />
              <Feed {...feedProps} />
            </section>
          )}
          {breakpoint.desktops && (
            <div
              className="u-ui-margin-top-large content-wrapper-flex"
              data-testid="item-desktops-only-container"
            >
              <section id="content" className="item-page-content-container">
                <div className="body-content">
                  <ContentSection {...contentProps} />
                  <Feed {...feedProps} />
                </div>
              </section>
              <aside id="sidebar" className="item-page-sidebar-container u-position-relative">
                <Sidebar item={itemDto} />
              </aside>
            </div>
          )}
          <ItemStickyButtons item={itemDto} />
        </main>
      </>
    )
  }

  return (
    <PluginsProvider plugins={serverSidePlugins} itemId={itemId}>
      <ShippingDetailsProvider shippingDetails={shippingDetails}>
        <EscrowFeesProvider escrowFees={escrowFees}>{renderContent()}</EscrowFeesProvider>
      </ShippingDetailsProvider>
    </PluginsProvider>
  )
}

const WrappedItem = (props: ComponentProps<typeof Item>) => (
  <ErrorBoundary FallbackComponent={ErrorModal} preventLog onError={logItemPageError}>
    <Item {...props} />
  </ErrorBoundary>
)

export default WrappedItem
