'use client'

import { Button, ColorCode } from '@vinted/web-ui'
import { Flag24 } from '@vinted/monochrome-icons'

import { useLocation } from '@marketplace-web/shared/browser'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { urlWithParams } from '@marketplace-web/shared/utils'
import { AlertType } from 'constants/admin'
import { NEW_ADMIN_ALERT_URL } from 'constants/routes'

import { ItemPageReportButtonPluginModel } from './types'

type Props = {
  data: ItemPageReportButtonPluginModel | undefined
}

const ItemPageReportButtonPlugin = ({ data }: Props) => {
  const translate = useTranslate()
  const { relativeUrl } = useLocation()
  const currentUser = useSession().user

  if (!data) return null
  if (data.isItemSold) return null
  if (currentUser && data.ownerId === currentUser.id) return null

  const url = urlWithParams(NEW_ADMIN_ALERT_URL, {
    ref_id: data.itemId,
    ref_type: AlertType.Item,
    ref_url: relativeUrl,
  })

  return (
    <Button
      iconName={Flag24}
      iconColor={ColorCode.GreyscaleLevel2}
      title={translate('item.actions.report')}
      styling={Button.Styling.Flat}
      url={url}
      inline
      rel="nofollow"
      testId="item-report-button"
    />
  )
}

export default ItemPageReportButtonPlugin
