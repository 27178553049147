export enum ShipmentStatus {
  New = 1,
  Estimated = 100,
  InstructionsConfirmed = 150,
  LabelProcessing = 200,
  LabelFailed = 210,
  LabelProcessed = 220,
  LabelSent = 230,
  TrackingPending = 250,
  Shipped = 300,
  HeldAtPostOffice = 310,
  LostParcel = 350,
  Delivered = 400,
  CancelDone = 500,
  ReturnToSender = 600,
  ReturnToSenderAvailableForPickup = 610,
  CancelFailed = 620,
  ReturnToSenderDelivered = 625,
  ReturnToSenderCompleted = 630,
  ShippedToFacility = 700,
  ArrivedAtFacility = 710,
  VerificationSuccessful = 720,
  VerificationFailed = 730,
  ShippedFromFacility = 740,
}

export enum ItemShippingDetailsDiscountType {
  All = 'all',
  Home = 'home',
  DropOff = 'drop_off',
}

export enum ShipmentEntryType {
  Preliminary = 20,
  Factual = 1,
  Return = 30,
}

export enum ShipmentDeliveryType {
  Home = 1,
  Pudo = 2,
}
