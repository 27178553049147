import { useMemo } from 'react'

import {
  getItemPageBuyerProtectionInfoPluginModel,
  getItemPageElectronicsVerificationBlockPluginModel,
  getItemPageOfflineVerificationBlockPluginModel,
  getItemPageOfflineVerificationStatusPluginModel,
  getItemPagePricingPluginModel,
  getItemPageShippingPricePluginModel,
  ItemPageBuyerProtectionInfoPluginModel,
  ItemPageElectronicsVerificationBlockPluginModel,
  ItemPageOfflineVerificationBlockPluginModel,
  ItemPageOfflineVerificationStatusPluginModel,
  ItemPagePricingPluginModel,
  ItemPageShippingPricePluginModel,
} from '@marketplace-web/domain/escrow-pricing'
import {
  getItemPageShopBundlesBlockPluginModel,
  ItemPageShopBundlesBlockPluginModel,
} from '@marketplace-web/domain/items-bundle'
import {
  getItemPageDeleteActionPluginModel,
  ItemPageDeleteActionModel,
} from '@marketplace-web/domain/listing'
import {
  getItemPageMakeOfferActionPluginModel,
  ItemPageMakeOfferActionPluginModel,
} from '@marketplace-web/domain/price-offers'
import {
  getItemPageUserInfoSellerBadgesPluginDto,
  ItemPageUserInfoSellerBadgesPluginDto,
} from '@marketplace-web/domain/seller-engagement-tools'
import {
  getItemPageBumpActionPluginModel,
  getItemPagePerformanceBlockPluginModel,
  ItemPageBumpActionModel,
  ItemPagePerformanceBlockModel,
} from '@marketplace-web/domain/vas'

import { ItemPageEditActionModel } from 'pages/Item/plugins/EditAction/types'
import { ItemPageHideActionModel } from 'pages/Item/plugins/HideAction/types'
import { ItemPageClosetCountdownActionPluginDto } from 'pages/Item/plugins/ClosetCountdownAction/types'
import { ItemPageMarkAsReservedButtonPluginModel } from 'pages/Item/plugins/MarkAsReservedButton/types'
import { ItemPageBuyButtonModel } from 'pages/Item/plugins/BuyButton/types'
import { ItemPageMarkAsSoldButtonPluginModel } from 'pages/Item/plugins/MarkAsSoldButton/types'
import { ItemPageAskSellerActionPluginModel } from 'pages/Item/plugins/AskSellerAction/types'
import { ItemPageReportButtonPluginModel } from 'pages/Item/plugins/ReportButton/types'

import { getItemPageEditActionPluginModel } from 'pages/Item/plugins/EditAction/transformers'
import { getItemPageHideActionPluginModel } from 'pages/Item/plugins/HideAction/transformers'
import { getItemPageClosetCountdownActionPluginDto } from 'pages/Item/plugins/ClosetCountdownAction/transformers'
import { getItemPageAskSellerActionPluginModel } from 'pages/Item/plugins/AskSellerAction/transformers'
import { getItemPageMarkAsReservedButtonPluginModel } from 'pages/Item/plugins/MarkAsReservedButton/transformers'
import { getItemPageMarkAsSoldButtonPluginModel } from 'pages/Item/plugins/MarkAsSoldButton/transformers'
import { getItemPageBuyButtonPluginModel } from 'pages/Item/plugins/BuyButton/transformers'
import { getItemPageReportButtonPluginModel } from 'pages/Item/plugins/ReportButton/transformers'
import { logItemPageError } from 'pages/Item/utils/observability'

import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageTransparencyLawPluginModel } from '../plugins/TransparencyLaw/types'
import { ItemPageLegalDpoFootnotePluginModel } from '../plugins/LegalDpoFootnote/types'
import { ItemPageUserInfoHeaderPluginModel } from '../plugins/UserInfoHeader/types'
import { ItemPageUserInfoDetailsPluginModel } from '../plugins/UserInfoDetails/types'
import { ItemPageUserInfoActionsPluginModel } from '../plugins/UserInfoActions/types'
import { ItemPageClosetTitlePluginModel } from '../plugins/ClosetTitle/types'
import { ItemPageClosetPluginModel } from '../plugins/Closet/types'
import { ItemPageSimilarItemsPluginModel } from '../plugins/SimilarItems/types'
import { ItemPageItemStatusPluginModel } from '../plugins/ItemStatus/types'
import { ItemPageGalleryPluginModel } from '../plugins/Gallery/types'

import { getItemPageUserInfoHeaderPluginModel } from '../plugins/UserInfoHeader/transformers'
import { getItemPageUserInfoDetailsPluginModel } from '../plugins/UserInfoDetails/transformers'
import { getItemPageUserInfoActionsPluginModel } from '../plugins/UserInfoActions/transformers'
import { getItemPageTransparencyLawPluginModel } from '../plugins/TransparencyLaw/transformers'
import { getItemPageLegalDpoFootnotePluginModel } from '../plugins/LegalDpoFootnote/transformers'
import { getItemPageClosetTitlePluginModel } from '../plugins/ClosetTitle/transformers'
import { getItemPageClosetPluginModel } from '../plugins/Closet/transformers'
import { getItemPageSimilarItemsPluginDto } from '../plugins/SimilarItems/transformers'
import { getItemPageItemStatusPluginModel } from '../plugins/ItemStatus/transformers'
import { getItemPageGalleryPluginModel } from '../plugins/Gallery/transformers'

export type Plugins = {
  editAction: ItemPageEditActionModel | null
  deleteAction: ItemPageDeleteActionModel | null
  hideAction: ItemPageHideActionModel | null
  bumpAction: ItemPageBumpActionModel | null
  makeOfferAction: ItemPageMakeOfferActionPluginModel | null
  gallery: ItemPageGalleryPluginModel | null
  closetCountdownAction?: ItemPageClosetCountdownActionPluginDto | null
  buyerProtectionInfo: ItemPageBuyerProtectionInfoPluginModel | null
  closet: ItemPageClosetPluginModel | null
  shippingPrice: ItemPageShippingPricePluginModel | null
  pricing: ItemPagePricingPluginModel | null
  similarItems: ItemPageSimilarItemsPluginModel | null
  askSellerAction: ItemPageAskSellerActionPluginModel | null
  performanceBlock: ItemPagePerformanceBlockModel | null
  offlineVerificationStatus: ItemPageOfflineVerificationStatusPluginModel | null
  offlineVerificationBlock: ItemPageOfflineVerificationBlockPluginModel | null
  shopBundlesBlock: ItemPageShopBundlesBlockPluginModel | null
  itemStatus: ItemPageItemStatusPluginModel | null
  markAsReservedButton: ItemPageMarkAsReservedButtonPluginModel | null
  markAsSoldButton: ItemPageMarkAsSoldButtonPluginModel | null
  reportButton: ItemPageReportButtonPluginModel | null
  buyButton: ItemPageBuyButtonModel | null
  transparencyLaw: ItemPageTransparencyLawPluginModel | null
  legalDpoFootnote: ItemPageLegalDpoFootnotePluginModel | null
  closetTitle: ItemPageClosetTitlePluginModel | null
  electronicsVerificationBlock: ItemPageElectronicsVerificationBlockPluginModel | null
  userInfoHeader: ItemPageUserInfoHeaderPluginModel | null
  userInfoSellerBadges: ItemPageUserInfoSellerBadgesPluginDto | null
  userInfoDetails: ItemPageUserInfoDetailsPluginModel | null
  userInfoActions: ItemPageUserInfoActionsPluginModel | null
}

export const getPlugins = (itemDto: ItemDetailsDto): Plugins => {
  const withCatch = <T>(
    getPluginModel: (itemDto: ItemDetailsDto) => T,
    pluginName: string,
  ): T | null => {
    try {
      return getPluginModel(itemDto)
    } catch (error) {
      logItemPageError(error, 'plugin_transformation_failed', `plugin: ${pluginName}`)

      return null
    }
  }

  return {
    editAction: withCatch(getItemPageEditActionPluginModel, 'edit'),
    deleteAction: withCatch(getItemPageDeleteActionPluginModel, 'delete'),
    hideAction: withCatch(getItemPageHideActionPluginModel, 'hide'),
    bumpAction: withCatch(getItemPageBumpActionPluginModel, 'bump'),
    makeOfferAction: withCatch(getItemPageMakeOfferActionPluginModel, 'make_offer'),
    gallery: withCatch(getItemPageGalleryPluginModel, 'gallery'),
    closetCountdownAction: withCatch(getItemPageClosetCountdownActionPluginDto, 'closet_countdown'),
    buyerProtectionInfo: withCatch(
      getItemPageBuyerProtectionInfoPluginModel,
      'buyer_protection_info',
    ),
    closet: withCatch(getItemPageClosetPluginModel, 'closet'),
    shippingPrice: withCatch(getItemPageShippingPricePluginModel, 'shipping_price'),
    pricing: withCatch(getItemPagePricingPluginModel, 'pricing'),
    similarItems: withCatch(getItemPageSimilarItemsPluginDto, 'similar_items'),
    askSellerAction: withCatch(getItemPageAskSellerActionPluginModel, 'ask_seller'),
    performanceBlock: withCatch(getItemPagePerformanceBlockPluginModel, 'performance'),
    offlineVerificationStatus: withCatch(
      getItemPageOfflineVerificationStatusPluginModel,
      'offline_verification_status',
    ),
    offlineVerificationBlock: withCatch(
      getItemPageOfflineVerificationBlockPluginModel,
      'offline_verification_block',
    ),
    shopBundlesBlock: withCatch(getItemPageShopBundlesBlockPluginModel, 'shop_bundles'),
    itemStatus: withCatch(getItemPageItemStatusPluginModel, 'item_status'),
    markAsReservedButton: withCatch(getItemPageMarkAsReservedButtonPluginModel, 'mark_as_reserved'),
    markAsSoldButton: withCatch(getItemPageMarkAsSoldButtonPluginModel, 'mark_as_sold'),
    buyButton: withCatch(getItemPageBuyButtonPluginModel, 'buy'),
    transparencyLaw: withCatch(getItemPageTransparencyLawPluginModel, 'transparency_law'),
    legalDpoFootnote: withCatch(getItemPageLegalDpoFootnotePluginModel, 'legal_dpo_footnote'),
    reportButton: withCatch(getItemPageReportButtonPluginModel, 'report'),
    closetTitle: withCatch(getItemPageClosetTitlePluginModel, 'closet_title'),
    electronicsVerificationBlock: withCatch(
      getItemPageElectronicsVerificationBlockPluginModel,
      'electronics_verification_block',
    ),
    userInfoHeader: withCatch(getItemPageUserInfoHeaderPluginModel, 'user_info_header'),
    userInfoSellerBadges: withCatch(
      getItemPageUserInfoSellerBadgesPluginDto,
      'user_info_seller_badges',
    ),
    userInfoDetails: withCatch(getItemPageUserInfoDetailsPluginModel, 'user_info_details'),
    userInfoActions: withCatch(getItemPageUserInfoActionsPluginModel, 'user_info_actions'),
  }
}

export const useItemPlugins = (itemDto: ItemDetailsDto): Plugins | null => {
  const pluginDtos = useMemo(() => {
    return getPlugins(itemDto)
  }, [itemDto])

  return pluginDtos
}
