'use client'

import { ReactNode, useCallback, useMemo, useState } from 'react'

import { UiState } from '@marketplace-web/shared/ui-helpers'
import { getPluginsByFeature } from 'data/api'
import { ItemPagePluginEntityDto } from 'types/dtos'

import PluginsContext from './PluginsContext'

type Props = {
  children: ReactNode
  plugins?: Array<ItemPagePluginEntityDto>
  itemId: number
}

const PluginsProvider = ({ children, plugins: serverSidePlugins, itemId }: Props) => {
  const [uiState, setUiState] = useState(UiState.Idle)
  const [plugins, setPlugins] = useState<Array<ItemPagePluginEntityDto> | undefined>(
    serverSidePlugins,
  )

  const updatePlugins = useCallback(
    async (feature: string, params?: Record<string, number | string | boolean>) => {
      setUiState(UiState.Pending)

      const response = await getPluginsByFeature({ itemId, feature, params })

      if ('errors' in response) {
        setUiState(UiState.Failure)

        return
      }

      const updatedPlugins =
        plugins?.map(existingPlugin => {
          const matchingNewPlugin = response.plugins.find(
            newPlugin => newPlugin.name === existingPlugin.name,
          )

          return matchingNewPlugin || existingPlugin
        }) || []

      const newPluginsToAdd = response.plugins.filter(
        newPlugin => !plugins?.some(existingPlugin => existingPlugin.name === newPlugin.name),
      )

      setPlugins([...updatedPlugins, ...newPluginsToAdd])
      setUiState(UiState.Success)
    },
    [plugins, itemId],
  )

  const pluginsMemo = useMemo(
    () => ({
      plugins,
      updatePlugins,
      uiState,
    }),
    [plugins, updatePlugins, uiState],
  )

  return <PluginsContext.Provider value={pluginsMemo}>{children}</PluginsContext.Provider>
}

export default PluginsProvider
