import { transformCurrencyAmountDtoOrString } from 'data/transformers/currency-amount'
import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPagePricingPluginModel } from './types'

export const getItemPagePricingPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPagePricingPluginModel => ({
  badge: itemDto.badge,
  itemId: itemDto.id,
  discountedPrice: itemDto.discount,
  isSellerBusiness: itemDto.user.business,
  itemUserId: itemDto.user.id,
  itemPrice: itemDto.price,
  totalItemPrice: itemDto.total_item_price,
  offerPrice: itemDto.offer_price
    ? transformCurrencyAmountDtoOrString(itemDto.offer_price, itemDto.currency)
    : null,
  serviceFee: itemDto.service_fee,
  itemTitle: itemDto.title,
  photos: itemDto.photos,
  conversion: itemDto.conversion,
})
