'use client'

import { useRef } from 'react'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'
import { Spacer } from '@vinted/web-ui'

import { ItemSearchParamsDto } from 'types/dtos/item-search'
import { ProductItemModel } from 'types/models/product-item'
import { TrackedProductItem } from 'components/ProductItem'
import { ContentSource } from 'constants/tracking/content-sources'
import { ListItemContentType } from 'constants/tracking/content-types'
import { ClickListItemEventArgs, impressionEvent } from '_libs/common/event-tracker/events'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { getSearchSessionData } from '_libs/utils/search'
import { Screen } from 'constants/tracking/screens'
import { getHomepageSessionIdFromUrl, withHomepageSessionIdFromUrl } from '_libs/utils/homepage'
import { HorizontalScrollArea } from '@marketplace-web/shared/ui-helpers'
import { useSession } from '@marketplace-web/shared/session'
import { useLocation } from '@marketplace-web/shared/browser'

type ItemModel = ProductItemModel & { search_tracking_params?: ItemSearchParamsDto | null }

type Props = {
  items: Array<ItemModel>
  contentSource: ContentSource
  horizontalScrollEnabled?: boolean
  itemTestId?: string
}

const ItemViewItems = ({
  items,
  contentSource,
  itemTestId,
  horizontalScrollEnabled = false,
}: Props) => {
  const { track } = useTracking()
  const seenItemIds = useRef<Array<number>>([])
  const currentUserId = useSession().user?.id
  const { searchParams } = useLocation()

  const searchSessionData = getSearchSessionData()
  const searchSessionIdRef = useRef(
    searchParams.referrer?.toString() === Screen.Catalog ? searchSessionData.searchSessionId : null,
  )

  const homepageSessionId = getHomepageSessionIdFromUrl()

  const handleItemVisibility = (item: ItemModel, position: number) => (inView: boolean) => {
    if (!inView) return

    const { id, user } = item
    const { id: itemOwnerId } = user
    const searchTrackingParams =
      'search_tracking_params' in item ? item.search_tracking_params : null

    if (seenItemIds.current.includes(id)) return

    track(
      impressionEvent({
        id,
        position,
        contentSource,
        itemOwnerId,
        globalSearchSessionId: searchSessionData.globalSearchSessionId,
        globalCatalogBrowseSessionId: searchSessionData.globalCatalogBrowseSessionId,
        searchSessionId: searchSessionIdRef.current,
        contentType: ListItemContentType.Item,
        searchScore: searchTrackingParams?.score,
        searchSignals: searchTrackingParams?.matched_queries,
        itemDistance: searchTrackingParams?.buyer_item_distance_km,
        homepageSessionId,
      }),
    )

    seenItemIds.current.push(id)
  }

  const renderItem = (item: ItemModel, index: number) => {
    const position = index + 1
    const { id } = item
    const searchTrackingParams =
      'search_tracking_params' in item ? item.search_tracking_params : null
    const trackingArgs: ClickListItemEventArgs = {
      id,
      position,
      contentSource,
      globalSearchSessionId: searchSessionData.globalSearchSessionId,
      searchSessionId: searchSessionIdRef.current,
      contentType: ListItemContentType.Item,
      searchScore: searchTrackingParams?.score,
      searchSignals: searchTrackingParams?.matched_queries,
      itemDistance: searchTrackingParams?.buyer_item_distance_km,
      homepageSessionId,
    }

    return (
      <InView
        onChange={handleItemVisibility(item, position)}
        key={id}
        className={classNames({
          'item-view-items__item': !horizontalScrollEnabled,
        })}
      >
        <TrackedProductItem
          item={item}
          showFavourite
          testId={itemTestId && `${itemTestId}-${id}`}
          tracking={trackingArgs}
          viewingSelf={currentUserId === item.user.id}
          hideBusinessBadge={contentSource === ContentSource.OtherUserItems}
          url={withHomepageSessionIdFromUrl(item.url)}
          isBumpTextHidden
          showStatus
          showOwner={false}
        />
      </InView>
    )
  }

  if (horizontalScrollEnabled)
    return (
      <div data-testid={`${itemTestId}-items`} className="item-page-horizontal-block">
        <Spacer size={Spacer.Size.Large} />
        <HorizontalScrollArea
          itemsFullWidthAlignment
          controlsScrollType={HorizontalScrollArea.ControlScrollType.Partial}
        >
          {items.map((item, index) => (
            <HorizontalScrollArea.Item className="item-view-items-horizontal__item" key={item.id}>
              {renderItem(item, index)}
            </HorizontalScrollArea.Item>
          ))}
        </HorizontalScrollArea>
      </div>
    )

  return <div className="item-view-items">{items.map(renderItem)}</div>
}

export default ItemViewItems
