'use client'

import { ReactNode, useState } from 'react'
import { Note, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'
import FaqEntryUrl from 'components/FaqEntryUrl'

import {
  PRIVACY_POLICY_URL,
  BUYER_PROTECTION_PRO_URL,
  BUSINESS_TERMS_OF_SALE_URL,
  BUSINESS_TERMS_AND_CONDITIONS_URL,
} from 'constants/routes'
import { FaqEntryType } from 'constants/faq-entry'

import { PoliciesModal } from 'pages/Profile/BusinessAccount'

import { ItemPageLegalDpoFootnotePluginModel } from './types'

type Props = {
  data?: ItemPageLegalDpoFootnotePluginModel
}

const ItemPageLegalDpoFootnotePlugin = ({ data }: Props) => {
  const [isPoliciesModalShown, setIsPoliciesModalShown] = useState(false)
  const translate = useTranslate('item.footnotes')

  if (!data) return null

  const { user } = data
  if (!user.business) return null

  function togglePoliciesModal() {
    setIsPoliciesModalShown(prevValue => !prevValue)
  }

  const legalValues = {
    'exceptions-link': (chunks: Array<ReactNode>) => (
      <FaqEntryUrl
        key="exceptions-link"
        type={FaqEntryType.BusinessRow}
        render={url => (
          <a href={url} data-testid="item-legal-footnote-exceptions-link">
            {chunks}
          </a>
        )}
      />
    ),
    'pro-terms-and-conditions-link': (chunks: Array<ReactNode>) => (
      <a
        key="pro-terms-and-conditions-link"
        href={BUSINESS_TERMS_AND_CONDITIONS_URL}
        data-testid="pro-terms-and-conditions-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        {chunks}
      </a>
    ),
    'pro-terms-of-sale-link': (chunks: Array<ReactNode>) => (
      <a
        key="pro-terms-of-sale-link"
        href={BUSINESS_TERMS_OF_SALE_URL}
        data-testid="item-legal-footnote-pro-terms-link"
      >
        {chunks}
      </a>
    ),
    'pro-sellers-policies': (chunks: Array<ReactNode>) => (
      <button key="pro-sellers-policies" type="button" onClick={togglePoliciesModal}>
        <Text
          as="span"
          clickable
          text={chunks}
          type={Text.Type.Caption}
          testId="item-legal-footnote-policies-link"
        />
      </button>
    ),
    'buyer-protection-pro-link': (chunks: Array<ReactNode>) => (
      <a
        key="buyer-protection-pro-link"
        href={BUYER_PROTECTION_PRO_URL}
        data-testid="item-legal-footnote-pro-protection-link"
      >
        {chunks}
      </a>
    ),
  }

  const dpoValues = {
    'here-link': (chunks: Array<ReactNode>) => (
      <span key="here-link">
        <button
          type="button"
          onClick={togglePoliciesModal}
          data-testid="item-dpo-footnote-here-link"
        >
          <Text as="span" clickable text={chunks} type={Text.Type.Caption} />
        </button>
      </span>
    ),
    'privacy-policy-link': (chunks: Array<ReactNode>) => (
      <a
        key="privacy-policy-link"
        href={PRIVACY_POLICY_URL}
        data-testid="item-dpo-privacy-policy-link"
      >
        {chunks}
      </a>
    ),
  }

  return (
    <>
      <Note
        styling={Note.Styling.Narrow}
        text={translate('legal', legalValues)}
        testId="item-legal-footnote"
      />
      <Note
        styling={Note.Styling.Narrow}
        text={translate('dpo', dpoValues)}
        testId="item-dpo-footnote"
      />
      <PoliciesModal user={user} show={isPoliciesModalShown} onClose={togglePoliciesModal} />
    </>
  )
}

export default ItemPageLegalDpoFootnotePlugin
