'use client'

import { useContext } from 'react'
import { InView } from 'react-intersection-observer'

import {
  ItemPageShopBundlesBlockPlugin,
  ItemPageShopBundlesBlockPluginModel,
} from '@marketplace-web/domain/items-bundle'
import { useAbTest, useTrackAbTestCallback } from '@marketplace-web/shared/ab-tests'
import {
  ItemPageClosetTitlePlugin,
  ItemPageClosetTitlePluginModel,
  ItemPageClosetPlugin,
  ItemPageClosetPluginModel,
  ItemPageSimilarItemsPlugin,
  ItemPageSimilarItemsPluginModel,
} from '@marketplace-web/domain/item-page'

import Plugin from '../plugins/Plugin'
import { PluginName, SectionType } from '../types'
import { PluginsContext } from '../containers/PluginsProvider'

type Props = {
  closetData: ItemPageClosetPluginModel | undefined
  similarItemsData: ItemPageSimilarItemsPluginModel | undefined
  shopBundlesBlockData: ItemPageShopBundlesBlockPluginModel | undefined
  closetTitleData: ItemPageClosetTitlePluginModel | undefined
}

const Feed = ({ closetData, similarItemsData, shopBundlesBlockData, closetTitleData }: Props) => {
  const { plugins: serverSidePlugins } = useContext(PluginsContext)

  const buyerHoldoutAbTestEnabled = useAbTest('buyer_domain_holdout_2025q1')?.variant === 'on'
  const horizontalBlocksTest = useAbTest('item_page_mixed_item_blocks')
  const trackAbTest = useTrackAbTestCallback()

  const horizontalBlockAbTestEnabled =
    buyerHoldoutAbTestEnabled && horizontalBlocksTest && horizontalBlocksTest.variant !== 'off'

  const feedPlugins = serverSidePlugins?.filter(plugin => plugin.section === SectionType.Feed)

  const renderFeedPlugins = () => {
    if (!feedPlugins?.length) return null

    return feedPlugins.flatMap(pluginProps => {
      const elements = [<Plugin key={pluginProps.name} data={pluginProps} />]

      if (pluginProps.name === PluginName.OtherUserItemsHeader && shopBundlesBlockData) {
        elements.push(
          <ItemPageShopBundlesBlockPlugin key="shop_bundles_block" data={shopBundlesBlockData} />,
        )
      }

      return elements
    })
  }

  const handleCrossSellingChannelsView = (inView: boolean) =>
    inView && buyerHoldoutAbTestEnabled && trackAbTest(horizontalBlocksTest)

  return (
    <div className="u-ui-margin-top-medium">
      <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Feedbacks)} />
      <InView as="div" onChange={handleCrossSellingChannelsView} triggerOnce>
        {horizontalBlockAbTestEnabled ? (
          renderFeedPlugins()
        ) : (
          <>
            <ItemPageClosetTitlePlugin data={closetTitleData} />
            {shopBundlesBlockData && <ItemPageShopBundlesBlockPlugin data={shopBundlesBlockData} />}
            {closetData && <ItemPageClosetPlugin data={closetData} />}
            <ItemPageSimilarItemsPlugin data={similarItemsData} />
          </>
        )}
      </InView>
    </div>
  )
}

export default Feed
