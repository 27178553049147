'use client'

import { Spacer } from '@vinted/web-ui'
import { useContext } from 'react'

import {
  ItemPagePricingPlugin,
  ItemPageShippingPricePlugin,
} from '@marketplace-web/domain/escrow-pricing'
import { Plugins } from '@marketplace-web/domain/item-page'

import { PluginsContext } from 'pages/Item/containers/PluginsProvider'
import { PluginName } from 'pages/Item/types'
import { ItemDetailsDto } from 'types/dtos/item-details'

import ItemActions from '../../ItemActions'
import Plugin from '../../plugins/Plugin'

type Props = {
  item: ItemDetailsDto
  data: Plugins | null
}

const DetailsSection = ({ item, data }: Props) => {
  const { plugins: serverSidePlugins } = useContext(PluginsContext)

  return (
    <>
      <div className="details-list details-list--main-info">
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Summary)} />
        {data?.pricing && <ItemPagePricingPlugin data={data.pricing} />}
        <Plugin
          data={serverSidePlugins?.find(plugin => plugin.name === PluginName.HighDemandSignal)}
        />
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Attributes)} />
      </div>
      <div className="details-list__info">
        <Plugin data={serverSidePlugins?.find(plugin => plugin.name === PluginName.Description)} />
        {data?.shippingPrice && <ItemPageShippingPricePlugin data={data.shippingPrice} />}
        <Spacer size={Spacer.Size.Large} />
        <div className="details-list__item details-list--actions">
          <ItemActions item={item} />
          <Spacer />
        </div>
      </div>
    </>
  )
}

export default DetailsSection
