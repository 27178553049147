'use client'

import { Card, Cell, Image, Rating, Spacer, Text } from '@vinted/web-ui'

import { navigateToPage } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { FormattedDateMessage, useTranslate } from '@marketplace-web/shared/i18n'
import { EMPTY_USER_IMAGE_NAME, useAsset } from '@marketplace-web/shared/ui-helpers'

import { clickEvent } from '_libs/common/event-tracker/events'
import { MEMBER_PROFILE_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'

import FeedbackWithTranslation from './FeedbackWithTranslation'
import { SellerFeedbackModel } from './types'

type Props = {
  feedback: SellerFeedbackModel
  sellerId: number
  itemId: number
}

const FeedbackCard = ({ feedback: data, sellerId, itemId }: Props) => {
  const { localization, rating, user, createdAt, feedback: originalFeedback } = data

  const translate = useTranslate()
  const { track } = useTracking()
  const asset = useAsset('assets/no-photo')

  const handleProfilePictureClick = () => {
    if (!user.id) return

    navigateToPage(MEMBER_PROFILE_URL(user.id))
    track(
      clickEvent({
        screen: Screen.Item,
        target: ClickableElement.SellerFeedbackUserInfoCell,
        targetDetails: JSON.stringify({
          owner_id: sellerId.toString(),
        }),
      }),
    )
  }

  return (
    <Card testId="seller-feedback--feedback-card">
      <article className="u-overflow-hidden">
        <Cell>
          <Cell
            prefix={
              <Image
                styling={Image.Styling.Circle}
                size={Image.Size.Medium}
                src={user.photo?.url || asset(EMPTY_USER_IMAGE_NAME)}
                alt={translate('item.seller_feedbacks.a11y.user_profile_picture', {
                  username: user.login,
                })}
              />
            }
            title={
              <Text
                alignment={Text.Alignment.Left}
                text={user.login}
                truncate
                as="p"
                theme="amplified"
                type={Text.Type.Subtitle}
              />
            }
            styling={Cell.Styling.Tight}
            onClick={handleProfilePictureClick}
            testId="feedback-card--profile-info-cell"
            clickable={!!user.id}
          />
          <Spacer size={Spacer.Size.Large} />
          <Cell styling={Cell.Styling.Tight}>
            <div className="u-flexbox u-overflow-hidden">
              <Rating
                value={rating}
                aria={{
                  'aria-label': rating
                    ? translate('user.a11y.rating', { rating, max_rating: 5 })
                    : '',
                }}
                size={Rating.Size.Regular}
              />
              <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
              <Text text="‧" type={Text.Type.Caption} as="div" />
              <Spacer orientation={Spacer.Orientation.Vertical} size={Spacer.Size.Small} />
              <Text
                text={<FormattedDateMessage date={new Date(createdAt)} relativeDate />}
                truncate
                type={Text.Type.Caption}
                as="div"
                tabIndex={0}
              />
            </div>

            <Spacer />

            <FeedbackWithTranslation
              originalFeedback={originalFeedback}
              localization={localization}
              feedbackId={data.id}
              itemId={itemId}
            />
          </Cell>
        </Cell>
      </article>
    </Card>
  )
}

export default FeedbackCard
