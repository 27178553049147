'use client'

import { createContext } from 'react'
import { noop } from 'lodash'

import { UiState } from '@marketplace-web/shared/ui-helpers'
import { ItemPagePluginEntityDto } from 'types/dtos'

export type PluginsContextType = {
  plugins?: Array<ItemPagePluginEntityDto>
  updatePlugins: (
    feature: string,
    params?: Record<string, number | string | boolean>,
  ) => Promise<void>
  uiState: UiState
}

const PluginsContext = createContext<PluginsContextType>({
  plugins: undefined,
  updatePlugins: () => new Promise(noop),
  uiState: UiState.Idle,
})

export default PluginsContext
