import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageUserInfoDetailsPluginModel } from './types'

export const getItemPageUserInfoDetailsPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageUserInfoDetailsPluginModel => ({
  user: itemDto.user,
  itemId: itemDto.id,
})
