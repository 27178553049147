import { InfoBanner, Spacer, Text } from '@vinted/web-ui'

import { ItemPageInfoBannerModel } from './types'

const InfoBannerPlugin = ({
  level,
  title,
  body,
  pluginName,
}: ItemPageInfoBannerModel & { pluginName: string }) => {
  return (
    <>
      <Spacer size={Spacer.Size.Large} />
      <InfoBanner
        body={
          body && <Text as="span" text={body} html type={Text.Type.Subtitle} theme="amplified" />
        }
        title={title}
        testId={`${pluginName}-info-banner`}
        type={level}
        styling={InfoBanner.Styling.Tight}
      />
      <Spacer />
    </>
  )
}

export default InfoBannerPlugin
