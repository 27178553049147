'use client'

import { useItemPlugins } from '@marketplace-web/domain/item-page'
import { ItemPageDeleteActionPlugin } from '@marketplace-web/domain/listing'
import { ItemPageMakeOfferActionPlugin } from '@marketplace-web/domain/price-offers'
import { ItemPageBumpActionPlugin } from '@marketplace-web/domain/vas'
import { ItemDetailsDto } from 'types/dtos/item-details'

import ItemPageAskSellerActionPlugin from '../plugins/AskSellerAction/ItemPageAskSellerActionPlugin'
import ItemPageBuyButtonPlugin from '../plugins/BuyButton/ItemPageBuyButtonPlugin'
import ItemPageCountdownActionPlugin from '../plugins/ClosetCountdownAction/ItemPageClosetCountdownActionPlugin'
import ItemPageEditActionPlugin from '../plugins/EditAction/ItemPageEditActionPlugin'
import ItemPageHideActionPlugin from '../plugins/HideAction/ItemPageHideActionPlugin'
import ItemPageMarkAsReservedButtonPlugin from '../plugins/MarkAsReservedButton/ItemPageMarkAsReservedButtonPlugin'
import ItemPageMarkAsSoldButtonPlugin from '../plugins/MarkAsSoldButton/ItemPageMarkAsSoldButtonPlugin'

type Props = {
  item: ItemDetailsDto
}

const ItemActions = ({ item }: Props) => {
  const plugins = useItemPlugins(item)

  return (
    <div className="u-grid u-gap-regular">
      {plugins?.buyButton && <ItemPageBuyButtonPlugin data={plugins.buyButton} />}
      {plugins?.makeOfferAction && <ItemPageMakeOfferActionPlugin data={plugins.makeOfferAction} />}
      {plugins?.closetCountdownAction && (
        <ItemPageCountdownActionPlugin data={plugins.closetCountdownAction} />
      )}
      {plugins?.askSellerAction && <ItemPageAskSellerActionPlugin data={plugins.askSellerAction} />}
      <ItemPageBumpActionPlugin bumpActionPluginModel={plugins?.bumpAction ?? undefined} />
      <ItemPageMarkAsSoldButtonPlugin data={plugins?.markAsSoldButton ?? undefined} />
      <ItemPageMarkAsReservedButtonPlugin data={plugins?.markAsReservedButton ?? undefined} />
      <ItemPageHideActionPlugin hideActionPluginModel={plugins?.hideAction ?? undefined} />
      <ItemPageEditActionPlugin editActionPluginModel={plugins?.editAction ?? undefined} />
      <ItemPageDeleteActionPlugin deleteActionPluginModel={plugins?.deleteAction ?? undefined} />
    </div>
  )
}

export default ItemActions
