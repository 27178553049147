import { UserDto } from '@marketplace-web/shared/user-data'

export const getUserLocationDescription = (user: UserDto) => {
  const { expose_location, city, country_title_local } = user

  if (expose_location && city) {
    return `${city}, ${country_title_local}`
  }

  return country_title_local
}
