'use client'

import { useSession } from '@marketplace-web/shared/session'

import { useCanTransact } from 'pages/Item/hooks/useCanTransact'

import { useShowStickyButtons } from 'pages/Item/hooks/useShowStickyButtons'
import { ConversationProvider } from '@marketplace-web/domain/core-conversation'

import { ItemPageMakeOfferActionPluginModel } from './types'
import { ItemBuyerOfferModalContainer } from './ItemBuyerOfferModalContainer/ItemBuyerOfferModalContainer'

type Props = {
  data: ItemPageMakeOfferActionPluginModel
  isStickyButton?: boolean
}

const ItemPageMakeOfferActionPlugin = ({ data, isStickyButton = false }: Props) => {
  const {
    sellerId,
    canBuy,
    canInstantBuy,
    isReserved,
    isHidden,
    reservedForUserId,
    closetCountdownEndDate,
  } = data

  const canTransact = useCanTransact({
    canBuy,
    canInstantBuy,
    isReserved,
    isHidden,
    reservedForUserId,
    closetCountdownEndDate,
  })
  const { user } = useSession()
  const currentUserId = user?.id
  const isViewingOwnItem = sellerId === currentUserId

  const showStickyButtons = useShowStickyButtons({ isViewingOwnItem })
  const isButtonHidden = isViewingOwnItem || !canTransact || showStickyButtons !== isStickyButton

  if (isButtonHidden) return null

  return (
    <ConversationProvider>
      <ItemBuyerOfferModalContainer data={data} />
    </ConversationProvider>
  )
}

export default ItemPageMakeOfferActionPlugin
