'use client'

import { PropsWithChildren, useState, useMemo, useCallback, useRef } from 'react'

import { OutsideClick } from '@marketplace-web/shared/ui-helpers'

import { ToggleableContext } from './ToggleableContext'
import Toggle from './Toggle'
import MenuItem from './MenuItem'
import Menu from './Menu'

type Props = {
  closeOnOutsideClick?: boolean
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const Toggleable = ({ children, closeOnOutsideClick, onClick }: PropsWithChildren<Props>) => {
  const [isVisible, setIsVisible] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const toggle = useCallback(() => {
    setIsVisible(prev => !prev)
  }, [])

  const close = useCallback(() => {
    setIsVisible(false)
  }, [])

  const value = useMemo(
    () => ({ toggle, close, isVisible, containerRef }),
    [close, toggle, isVisible, containerRef],
  )

  const handleOutsideClick = () => {
    if (closeOnOutsideClick && isVisible) close()
  }

  return (
    <ToggleableContext.Provider value={value}>
      <OutsideClick isDisabled={!closeOnOutsideClick} onOutsideClick={handleOutsideClick}>
        <div onClick={onClick} role="none" ref={containerRef}>
          {children}
        </div>
      </OutsideClick>
    </ToggleableContext.Provider>
  )
}

Toggleable.Menu = Menu
Toggleable.MenuItem = MenuItem
Toggleable.Toggle = Toggle

export default Toggleable
