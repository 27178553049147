'use client'

import { ReactNode } from 'react'

import { ItemPageAdminSectionDto, ItemPagePluginEntityDto } from 'types/dtos/item-page-plugins'
import { ItemPageSellerBadgesInfoDto } from 'types/dtos'
import ExposureBlock from 'components/ExposureBlock'
import { clientSideMetrics } from '@marketplace-web/shared/metrics'
import {
  ItemPageSellerBadgesInfoPlugin,
  getItemPageSellerBadgesInfoPluginModel,
  ItemPageSellerBadgesInfoPluginModel,
} from '@marketplace-web/domain/seller-engagement-tools'
import {
  ItemPageSummaryDto,
  ItemPageSummaryPluginModel,
  ItemPageSummaryPlugin,
  getItemPageSummaryPluginModel,
  ItemPageBreadcrumbsPluginModel,
  ItemPageBreadcrumbsPlugin,
  getItemPageBreadcrumbsPluginModel,
  ItemPageSellerFeedbacksPlugin,
  ItemPageSellerFeedbacksPluginModel,
  getItemPageSellerFeedbacksPluginModel,
  ItemPageSellerFeedbacksDto,
  ItemPageAttributesBlockPluginModel,
  ItemPageAttributesBlockPlugin,
  getItemPageAttributesBlockPluginModel,
  ItemPageAttributesDto,
  getItemPageItemsBlockHeaderPluginModel,
  getItemPageItemsBlockPluginModel,
  ItemPageItemsBlockHeaderPluginModel,
  ItemPageItemsBlockPluginModel,
  ItemPageItemsBlockPlugin,
  ItemPageItemsBlockHeaderPlugin,
  ItemPageItemsBlockDto,
  ItemPageItemsBlockHeaderDto,
  ItemPageAlertDto,
  ItemPageDescriptionPlugin,
  getItemPageDescriptionPluginModel,
  ItemPageDescriptionPluginModel,
  ItemPageDescriptionDto,
  ItemPageInfoBannerModel,
  ItemPageInfoBannerPlugin,
  getItemPageInfoBannerPluginModel,
} from '@marketplace-web/domain/item-page'

import { PluginType } from '../types'
import { logItemPageError } from '../utils/observability'
import { ItemPageAdminSectionPluginModel } from './AdminSection/types'
import ItemPageAdminSectionPlugin from './AdminSection/ItemPageAdminSectionPlugin'
import { getItemPageAdminSectionPluginModel } from './AdminSection/transformers'
import {
  getItemPageItemAlertsPluginModel,
  ItemPageAlertsPlugin,
  ItemPageAlertsPluginModel,
} from './Alerts'

const ITEM_PAGE_PLUGIN_ERROR_COUNT_METRIC_NAME = 'item_page_plugin_error_count'

type TransformerParams<TBackendData> = {
  data: TBackendData
  pluginName: string
}

type PluginConfig<TBackendData, TProps> = {
  component: (props: TProps & { pluginName: string }) => ReactNode
  transformer: (params: TransformerParams<TBackendData>) => TProps | null
}

type PluginConfigs = {
  [PluginType.Feedbacks]: PluginConfig<
    ItemPageSellerFeedbacksDto,
    ItemPageSellerFeedbacksPluginModel
  >
  [PluginType.Attributes]: PluginConfig<ItemPageAttributesDto, ItemPageAttributesBlockPluginModel>
  [PluginType.Summary]: PluginConfig<ItemPageSummaryDto, ItemPageSummaryPluginModel>
  [PluginType.Description]: PluginConfig<ItemPageDescriptionDto, ItemPageDescriptionPluginModel>
  [PluginType.SellerBadgesInfo]: PluginConfig<
    ItemPageSellerBadgesInfoDto,
    ItemPageSellerBadgesInfoPluginModel
  >
  [PluginType.ItemsBlockHeader]: PluginConfig<
    ItemPageItemsBlockHeaderDto,
    ItemPageItemsBlockHeaderPluginModel
  >
  [PluginType.HorizontalItemsBlock]: PluginConfig<
    ItemPageItemsBlockDto,
    ItemPageItemsBlockPluginModel
  >
  [PluginType.VerticalItemsBlock]: PluginConfig<
    ItemPageItemsBlockDto,
    ItemPageItemsBlockPluginModel
  >
  [PluginType.AdminSection]: PluginConfig<ItemPageAdminSectionDto, ItemPageAdminSectionPluginModel>
  [PluginType.Alert]: PluginConfig<ItemPageAlertDto, ItemPageAlertsPluginModel>
  [PluginType.Breadcrumbs]: PluginConfig<
    ItemPageBreadcrumbsPluginModel,
    ItemPageBreadcrumbsPluginModel
  >
  [PluginType.InfoBanner]: PluginConfig<ItemPageInfoBannerModel, ItemPageInfoBannerModel>
}

const withCatch = <TData, TProps>(
  { data, pluginName }: TransformerParams<TData>,
  transformPluginData: (data: TData) => TProps,
): TProps | null => {
  try {
    return transformPluginData(data)
  } catch (error) {
    logItemPageError(error, 'be_plugin_transformation_failed', `plugin: ${pluginName}`)

    return null
  }
}

const pluginConfigs: PluginConfigs = {
  [PluginType.Feedbacks]: {
    component: ItemPageSellerFeedbacksPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageSellerFeedbacksPluginModel),
  },
  [PluginType.Attributes]: {
    component: ItemPageAttributesBlockPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageAttributesBlockPluginModel),
  },
  [PluginType.Summary]: {
    component: ItemPageSummaryPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageSummaryPluginModel),
  },
  [PluginType.Description]: {
    component: ItemPageDescriptionPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageDescriptionPluginModel),
  },
  [PluginType.SellerBadgesInfo]: {
    component: ItemPageSellerBadgesInfoPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageSellerBadgesInfoPluginModel),
  },
  [PluginType.HorizontalItemsBlock]: {
    component: ItemPageItemsBlockPlugin,
    transformer: pluginProps =>
      withCatch(pluginProps, data => getItemPageItemsBlockPluginModel(data, true)),
  },
  [PluginType.VerticalItemsBlock]: {
    component: ItemPageItemsBlockPlugin,
    transformer: pluginProps =>
      withCatch(pluginProps, data => getItemPageItemsBlockPluginModel(data, false)),
  },
  [PluginType.ItemsBlockHeader]: {
    component: ItemPageItemsBlockHeaderPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageItemsBlockHeaderPluginModel),
  },
  [PluginType.AdminSection]: {
    component: ItemPageAdminSectionPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageAdminSectionPluginModel),
  },
  [PluginType.Alert]: {
    component: ItemPageAlertsPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageItemAlertsPluginModel),
  },
  [PluginType.Breadcrumbs]: {
    component: ItemPageBreadcrumbsPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageBreadcrumbsPluginModel),
  },
  [PluginType.InfoBanner]: {
    component: ItemPageInfoBannerPlugin,
    transformer: pluginProps => withCatch(pluginProps, getItemPageInfoBannerPluginModel),
  },
}

type Props = {
  data: ItemPagePluginEntityDto | undefined
}

// /**
//  * @example
//  * const ExampleSidebarComponent = (props) => {
//  *    const relevantPlugins = plugins.filter(p => p.section == 'sidebar')
//  *    return <>
//  *      {relevantPlugins.map(p => <Plugin {...p} />)}
//  *    </>
//  * }
//  */
const Plugin = ({ data }: Props) => {
  if (!data) return undefined

  const pluginConfig: any = pluginConfigs[data.type]
  if (!pluginConfig) return undefined

  const componentProps = pluginConfig.transformer({ data: data.data, pluginName: data.name })
  if (!componentProps) {
    clientSideMetrics
      .counter(ITEM_PAGE_PLUGIN_ERROR_COUNT_METRIC_NAME, { name: data.name })
      .increment()
  }

  const Component = pluginConfig.component

  return (
    <>
      {data.exposure && <ExposureBlock {...data.exposure} />}
      {componentProps && <Component {...componentProps} pluginName={data.name} />}
    </>
  )
}

export default Plugin
