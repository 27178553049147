'use client'

import { Cell, Spacer, Text } from '@vinted/web-ui'
import { useInView } from 'react-intersection-observer'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import {
  itemPageChannelClickEvent,
  itemPageChannelViewEvent,
} from '_libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { ItemPageItemsBlockHeaderPluginModel } from './types'

const ItemPageItemsBlockHeaderPlugin = ({
  title,
  headerCta,
  pluginName,
  itemId,
  contentSource,
}: ItemPageItemsBlockHeaderPluginModel & { pluginName: string }) => {
  const { track } = useTracking()
  const { ref } = useInView({
    onChange: inView => inView && track(itemPageChannelViewEvent({ itemId, contentSource })),
    triggerOnce: true,
  })

  const handleCtaClick = () => {
    track(
      itemPageChannelClickEvent({
        itemId,
        contentSource,
        target: ClickableElement.ItemsBlockHeaderCta,
      }),
    )
  }

  const renderSuffix = () =>
    headerCta && (
      <a
        href={headerCta.url}
        onClick={handleCtaClick}
        target="_blank"
        data-testid="horizontal-items-block-header-suffix"
        rel="noreferrer"
      >
        <Text type={Text.Type.Subtitle} theme="primary" text={headerCta.title} as="span" bold />
      </a>
    )

  return (
    <div ref={ref}>
      <Spacer size={Spacer.Size.XLarge} />
      <Cell
        title={
          <Text type={Text.Type.Title} as="h2">
            {title}
          </Text>
        }
        suffix={renderSuffix()}
        styling={Cell.Styling.Tight}
        testId={`item-page-${pluginName}-plugin`}
      />
    </div>
  )
}

export default ItemPageItemsBlockHeaderPlugin
