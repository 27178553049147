'use client'

import { MouseEvent } from 'react'
import { Spacing, Cell } from '@vinted/web-ui'
import { ResponsiveContainer, LineChart, Line, XAxis } from 'recharts'
import { isNumber } from 'lodash'

import { useIsDarkModeEnabledFromCookies } from '@marketplace-web/shared/dark-mode'

import { getPrimaryColor } from '../../../../../utils/colors'
import Label from './Label'
import Dot from './Dot'
import { DataPoint } from './types'

type Props = {
  data: Array<DataPoint>
  activePoint?: number | null
  interactive: boolean
  width?: number | string
  height?: number | string
  onClick?: (event: MouseEvent) => void
  onPointClick?: (payload?: DataPoint, index?: number) => void
}

const CHART_MARGINS = {
  top: Spacing.Medium,
  right: Spacing.Large,
  left: Spacing.Large,
  bottom: Spacing.Medium,
}

const PerformanceChart = ({
  data = [],
  activePoint,
  interactive = false,
  width = '100%',
  height = 150,
  onClick,
  onPointClick,
}: Props) => {
  const isDarkModeEnabled = useIsDarkModeEnabledFromCookies()

  const handleClick = (event: MouseEvent) => {
    if (!interactive) return

    if (onClick) onClick(event)
  }

  const handlePointClick = (payload?: DataPoint, index?: number) => {
    if (!interactive) return

    if (onPointClick) onPointClick(payload, index)
  }

  const areDimensionsNumbers = isNumber(width) && isNumber(height)

  const renderChartContent = () => {
    const chartProps = areDimensionsNumbers ? { width, height } : null

    const dot = (
      <Dot
        testId="performance-chart-dot"
        onClick={handlePointClick}
        activePoint={activePoint}
        interactive={interactive}
      />
    )

    return (
      <LineChart {...chartProps} data={data} margin={CHART_MARGINS}>
        <XAxis dataKey="label" axisLine={false} tickLine={false} tick={<Label />} interval={0} />

        <Line dataKey="value_1" stroke="#999" strokeWidth={2} dot={dot} isAnimationActive={false} />

        <Line
          dataKey="value_2"
          stroke={getPrimaryColor(isDarkModeEnabled)}
          strokeWidth={2}
          dot={dot}
          isAnimationActive={false}
        />
      </LineChart>
    )
  }

  const renderChart = () => {
    if (areDimensionsNumbers) return renderChartContent()

    return (
      <ResponsiveContainer width={width} height={height}>
        {renderChartContent()}
      </ResponsiveContainer>
    )
  }

  return (
    <Cell styling={Cell.Styling.Tight} onClick={handleClick} testId="performance-chart">
      {renderChart()}
    </Cell>
  )
}

export default PerformanceChart
