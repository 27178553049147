'use client'

import { Badge, Cell, Image, Rating, Spacer } from '@vinted/web-ui'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { UserImage, UserTitle } from '@marketplace-web/shared/ui-helpers'
import { profileClickEvent } from '_libs/common/event-tracker/events'
import { MEMBER_PROFILE_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { calculateRating } from 'data/utils/user-rating'

import { ItemPageUserInfoHeaderPluginModel } from './types'

type Props = {
  data: ItemPageUserInfoHeaderPluginModel
}

const ItemPageUserInfoHeaderPlugin = ({ data }: Props) => {
  const translate = useTranslate()
  const { track } = useTracking()

  const { user, itemId } = data
  if (!user) return null

  const rating = calculateRating(user.feedback_reputation)

  const handleOnClick = () => {
    if (!user) return

    track(
      profileClickEvent({
        target: ClickableElement.UserShortInfoCell,
        ownerId: user.id.toString(),
        itemId,
      }),
    )
  }

  return (
    <Cell
      prefix={
        <UserImage src={user.photo?.url} styling={Image.Styling.Circle} size={Image.Size.Large} />
      }
      title={
        <UserTitle
          businessAccountName={user.business_account?.name}
          login={user.login}
          isBusiness={user.business}
          isBody
          isBottomLoginHidden
          isBadgeHidden
        />
      }
      onClick={handleOnClick}
      url={MEMBER_PROFILE_URL(user.id)}
      type={Cell.Type.Navigating}
      chevron
    >
      <Rating
        aria={{
          'aria-label': (rating && translate('user.a11y.rating', { rating, max_rating: 5 })) || '',
        }}
        value={rating}
        text={user.feedback_count}
        size={Rating.Size.Small}
        emptyStateText={translate('user.feedback.no_reviews')}
      />
      {user.business && (
        <>
          <Spacer size={Spacer.Size.Small} />
          <Badge content={translate('business.title')} testId="seller-pro-badge" />
        </>
      )}
    </Cell>
  )
}

export default ItemPageUserInfoHeaderPlugin
