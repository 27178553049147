'use client'

import { useCallback, useEffect, useState } from 'react'

import { urlWithParams } from '@marketplace-web/shared/utils'
import { ADMIN_SECTION_ITEM_PAGE_URL } from 'constants/routes'

import { ItemPageAdminSectionPluginModel } from './types'

const IFRAME_MIN_HEIGHT = '300px'
const IFRAME_MAX_HEIGHT = '2500px'

const ItemPageAdminSectionPlugin = ({ itemId }: ItemPageAdminSectionPluginModel) => {
  const [iframeDocument, setIframeDocument] = useState<Document | undefined | null>(null)

  useEffect(() => {
    const iframe = document.getElementById('item-admin-section-iframe') as HTMLIFrameElement

    const handleInitIframeDocument = () => {
      setIframeDocument(iframe.contentDocument || iframe.contentWindow?.document)
    }

    iframe.addEventListener('load', handleInitIframeDocument)

    return () => iframe.removeEventListener('load', handleInitIframeDocument)
  }, [])

  const updateIframeHeight = useCallback(() => {
    if (!iframeDocument) return

    const iframe = document.getElementById('item-admin-section-iframe') as HTMLIFrameElement
    const adminDropdownMenu: Partial<NodeListOf<Element>> =
      iframeDocument.querySelectorAll('.dropdown-wrapper')
    const adminMenuContainer: HTMLElement | null =
      iframeDocument.querySelector('.c-cell > .container')

    // Makes iframe body unscrollable
    iframeDocument.body.style.overflow = 'hidden'

    if (adminDropdownMenu[0]?.classList.contains('open')) {
      iframe.style.height = IFRAME_MAX_HEIGHT

      return
    }

    iframe.style.height = adminMenuContainer
      ? `${adminMenuContainer?.offsetHeight}px`
      : IFRAME_MIN_HEIGHT
  }, [iframeDocument])

  useEffect(() => {
    if (!iframeDocument) return undefined

    const observer = new MutationObserver(() => {
      updateIframeHeight()
    })

    observer.observe(iframeDocument.body, {
      attributes: true,
      attributeFilter: ['class'],
      childList: true,
      subtree: true,
    })

    return () => observer.disconnect()
  }, [iframeDocument, updateIframeHeight])

  const url = urlWithParams(ADMIN_SECTION_ITEM_PAGE_URL(itemId), {
    content_only: 1,
  })

  return (
    <iframe
      id="item-admin-section-iframe"
      title="Admin section"
      style={{ border: 'none', width: '100%', minHeight: IFRAME_MIN_HEIGHT }}
      src={url}
    />
  )
}

export default ItemPageAdminSectionPlugin
