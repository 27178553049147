import { ItemPageAlertDto } from '@marketplace-web/domain/item-page'
import { transformItemAlertDto } from 'data/transformers/item-alert'

import { ItemPageAlertsPluginModel } from './types'

export const getItemPageItemAlertsPluginModel = (
  data: ItemPageAlertDto,
): ItemPageAlertsPluginModel => ({
  itemId: data.item_id,
  itemAlert: transformItemAlertDto(data.item_alert),
})
