import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/app/pages/Item/Item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/libs/domain/country/src/components/DomainSelectModal/DomainSelectCookieSetOnRedirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/libs/domain/country/src/components/DomainSelectModal/DomainSelectModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CookieManagerContext"] */ "/app/libs/shared/cookies/src/context/cookie-manager-context.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/libs/shared/cookies/src/context/cookie-manager-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/libs/shared/environment/src/EnvsProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/libs/shared/ui-helpers/src/components/JsonLd/JsonLd.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/libs/shared/ui-helpers/src/components/UserImage/UserImage.tsx");
