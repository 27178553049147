import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageClosetPluginModel } from './types'

export const getItemPageClosetPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageClosetPluginModel => ({
  itemId: itemDto.id,
  canBuyItem: itemDto.can_buy,
  canBundle: itemDto.can_bundle,
  closetCountdownEndDate: itemDto.user.closet_countdown_end_date || '',
  itemUserId: itemDto.user.id,
  bundleDiscount: itemDto.user.bundle_discount,
  isUserHated: itemDto.user.is_hated,
  isUserHatesYou: itemDto.user.hates_you,
})
