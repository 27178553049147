'use client'

import { Button } from '@vinted/web-ui'

import { navigateToPage } from '@marketplace-web/shared/browser'
import { clickEvent } from '_libs/common/event-tracker/events'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useSession } from '@marketplace-web/shared/session'
import { EDIT_ITEM_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import { ClickableElement } from 'constants/tracking/clickable-elements'

import { ItemPageEditActionModel } from './types'

type Props = {
  editActionPluginModel: ItemPageEditActionModel | undefined
}

const ItemPageEditActionPlugin = ({ editActionPluginModel }: Props) => {
  const translate = useTranslate('item')
  const { track } = useTracking()
  const currentUserId = useSession().user?.id

  if (!editActionPluginModel) return null

  const { id, userId, canEdit } = editActionPluginModel
  const isViewingOwnItem = userId === currentUserId

  const handleButtonClick = () => {
    track(
      clickEvent({
        target: ClickableElement.EditItem,
        screen: Screen.Item,
        targetDetails: JSON.stringify({
          item_id: id,
          user_id: userId,
        }),
      }),
    )

    navigateToPage(EDIT_ITEM_URL(id))
  }

  if (!isViewingOwnItem) return null
  if (!canEdit) return null

  return (
    <Button
      text={translate('actions.edit')}
      onClick={handleButtonClick}
      size={Button.Size.Medium}
      testId="item-edit-button"
    />
  )
}

export default ItemPageEditActionPlugin
