'use client'

import { Card, Cell, Divider, Spacer } from '@vinted/web-ui'
import { useContext, useEffect } from 'react'

import { AdShape, Advertisement } from '@marketplace-web/domain/ads'
import {
  ItemElectronicsVerificationBlockPlugin,
  ItemPageBuyerProtectionInfoPlugin,
  ItemPageElectronicsVerificationStatusPlugin,
  ItemPageOfflineVerificationBlockPlugin,
  ItemPageOfflineVerificationStatusPlugin,
} from '@marketplace-web/domain/escrow-pricing'
import { useGoogleTagManagerTrack } from '@marketplace-web/domain/google-track'
import {
  ItemPageItemStatusPlugin,
  ItemPageLegalDpoFootnotePlugin,
  ItemPageTransparencyLawPlugin,
  ItemPageUserInfoActionsPlugin,
  ItemPageUserInfoDetailsPlugin,
  ItemPageUserInfoHeaderPlugin,
  useItemPlugins,
} from '@marketplace-web/domain/item-page'
import {
  ItemPageUserInfoSellerBadgesPlugin,
  useSellerBadgeAbTest,
} from '@marketplace-web/domain/seller-engagement-tools'
import { ItemPagePerformanceBlockPlugin } from '@marketplace-web/domain/vas'

import { useFeatureSwitch } from '@marketplace-web/shared/feature-switches'
import { useSession } from '@marketplace-web/shared/session'
import { SeparatedList } from '@marketplace-web/shared/ui-helpers'

import InfoBanner from 'components/InfoBanner'
import { GoogleTagManagerEvent } from 'constants/google'
import { ItemPageSellerBadgesInfoDto } from 'types/dtos'
import { ItemDetailsDto } from 'types/dtos/item-details'

import { PluginsContext } from '../containers/PluginsProvider'
import Plugin from '../plugins/Plugin'
import { PluginName } from '../types'
import DetailsSection from './DetailsSection'

type Props = {
  item: ItemDetailsDto
}

const Sidebar = ({ item }: Props) => {
  const { screen: screenName } = useSession()
  const { googleTagManagerTrack } = useGoogleTagManagerTrack()
  const plugins = useItemPlugins(item)
  const { plugins: serverSidePlugins } = useContext(PluginsContext)

  const isFastReplierBadgeEnabled = useFeatureSwitch('web_fast_replier_badge')

  useSellerBadgeAbTest({
    user: item.user,
    abTestName: 'fast_replier_badge_v3',
    shouldExposeSelf: false,
  })

  useEffect(() => {
    const value = parseFloat(item.price.amount)
    const price = parseFloat(item.discount_price?.amount || item.price.amount)
    const discount = Math.round((value - price) * 100) / 100
    googleTagManagerTrack(GoogleTagManagerEvent.ItemView, {
      item_name: item.title,
      item_id: item.id,
      value,
      price,
      discount,
      currency: item.currency,
      user_email: item.user.email,
      item_category_id: item.catalog_id,
      item_brand: item.brand_dto?.title,
      item_brand_id: item.brand_dto?.id,
      item_condition: item.disposal_conditions,
    })
  }, [googleTagManagerTrack, item])

  // will remove this function after server side plugin ab test is scaled - for now this seems cleaner this way
  const renderSellerBadgesPlugin = () => {
    const serverSideSellerBadgesPlugin = serverSidePlugins?.find(
      plugin => plugin.name === PluginName.SellerBadgesInfo,
    )

    if (
      isFastReplierBadgeEnabled &&
      serverSideSellerBadgesPlugin &&
      (serverSideSellerBadgesPlugin?.data as ItemPageSellerBadgesInfoDto).badges.length
    ) {
      return <Plugin data={serverSideSellerBadgesPlugin} />
    }

    if (!plugins?.userInfoSellerBadges?.seller_badges?.length) return null

    return <ItemPageUserInfoSellerBadgesPlugin data={plugins.userInfoSellerBadges} />
  }

  return (
    <>
      <div className="u-hidden@desktops">
        <Spacer size={Spacer.Size.Large} />
      </div>
      <div className="item-page-sidebar-content">
        <InfoBanner screen={screenName} />
        <ItemPagePerformanceBlockPlugin
          performanceBlockPluginModel={plugins?.performanceBlock ?? undefined}
        />
        <Card>
          <div className="u-overflow-hidden">
            <ItemPageItemStatusPlugin data={plugins?.itemStatus ?? undefined} />
            {plugins?.offlineVerificationStatus && (
              <ItemPageOfflineVerificationStatusPlugin data={plugins.offlineVerificationStatus} />
            )}
            <ItemPageElectronicsVerificationStatusPlugin />
            <Cell>
              <div className="box--item-details">
                <DetailsSection item={item} data={plugins} />
              </div>
            </Cell>
          </div>
        </Card>
        {plugins?.offlineVerificationBlock && (
          <ItemPageOfflineVerificationBlockPlugin data={plugins.offlineVerificationBlock} />
        )}
        {plugins?.electronicsVerificationBlock && (
          <ItemElectronicsVerificationBlockPlugin data={plugins.electronicsVerificationBlock} />
        )}
        {plugins?.buyerProtectionInfo && (
          <ItemPageBuyerProtectionInfoPlugin data={plugins.buyerProtectionInfo} />
        )}
        <Card>
          <div className="u-overflow-hidden">
            <SeparatedList separator={<Divider />}>
              {plugins?.userInfoHeader && (
                <ItemPageUserInfoHeaderPlugin data={plugins.userInfoHeader} />
              )}
              {renderSellerBadgesPlugin()}
              {plugins?.userInfoDetails && (
                <ItemPageUserInfoDetailsPlugin data={plugins.userInfoDetails} />
              )}
              {plugins?.userInfoActions && (
                <ItemPageUserInfoActionsPlugin data={plugins.userInfoActions} />
              )}
            </SeparatedList>
          </div>
        </Card>
      </div>

      <ItemPageLegalDpoFootnotePlugin data={plugins?.legalDpoFootnote ?? undefined} />
      <ItemPageTransparencyLawPlugin data={plugins?.transparencyLaw ?? undefined} />
      <Advertisement shape={AdShape.Rectangle} id="ad-rectangle" isSidebarAd />
      <Advertisement shape={AdShape.Skyscraper} id="ad-skyscraper" isSidebarAd />
    </>
  )
}

export default Sidebar
