'use client'

import { Cell, Text } from '@vinted/web-ui'
import { useMemo, useState } from 'react'

import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSystemConfiguration } from '@marketplace-web/shared/system-configuration'

enum TransparencyLawSubject {
  BuyerRights = 'buyerRights',
  ProfessionalSeller = 'professionalSeller',
}

type Props = {
  subject: TransparencyLawSubject
}

const TransparencyLaw = ({ subject }: Props) => {
  const translate = useTranslate()
  const systemConfiguration = useSystemConfiguration()

  const [isExpanded, setIsExpanded] = useState(false)
  const [text, secondText] = useMemo(() => {
    if (!systemConfiguration) return ['', '']

    const key = systemConfiguration.transparencyLawKeys[subject]

    if (!key) return ['', '']

    const translated = translate(key)
    const translatedText = translated ? translated.toString().split('[read_more]') : ['', '']

    // eslint-disable-next-line @typescript-eslint/no-extra-non-null-assertion
    return [`${translatedText[0]!?.trim()} `, translatedText[1]?.trim() || '']
  }, [translate, systemConfiguration, subject])

  const handleActionClick = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded)
  }

  const renderAction = () => {
    if (isExpanded || !secondText) return null

    return (
      <button type="button" onClick={handleActionClick}>
        <Text
          testId="see-more-legal-notes"
          inline
          type={Text.Type.Caption}
          clickable
          text={translate('transparency_law.actions.read_more')}
          as="span"
        />
      </button>
    )
  }

  if (!text) return null

  return (
    <Cell theme="transparent" testId="item-transparency-law">
      <Text
        html
        inline
        type={Text.Type.Caption}
        as="p"
        text={isExpanded ? text + secondText : text}
      />
      {renderAction()}
    </Cell>
  )
}

TransparencyLaw.TransparencyLawSubject = TransparencyLawSubject

export default TransparencyLaw
