import { ItemDetailsDto } from 'types/dtos/item-details'

export const getItemPageClosetCountdownActionPluginDto = (itemDto: ItemDetailsDto) => {
  const endDate = itemDto.user.closet_countdown_end_date

  if (!endDate) return undefined

  return {
    userId: itemDto.user.id,
    endDate,
    ownerLogin: itemDto.user.login,
    isFollowed: itemDto.user.is_favourite,
    isHatePresent: itemDto.user.is_hated || itemDto.user.hates_you,
    itemId: itemDto.id.toString(),
    canBuy: itemDto.can_buy,
    canInstantBuy: itemDto.instant_buy,
    isReserved: itemDto.is_reserved,
    isHidden: itemDto.is_hidden,
    reservedForUserId: itemDto.reservation?.to_whom_id,
  }
}
