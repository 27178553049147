import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageUserInfoHeaderPluginModel } from './types'

export const getItemPageUserInfoHeaderPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageUserInfoHeaderPluginModel => ({
  user: itemDto.user,
  itemId: itemDto.id.toString(),
})
