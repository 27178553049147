import TransparencyLaw from 'components/TransparencyLaw'

import { ItemPageTransparencyLawPluginModel } from './types'

type Props = {
  data?: ItemPageTransparencyLawPluginModel
}

const ItemPageTransparencyLawPlugin = ({ data }: Props) => {
  if (!data) return null
  if (data.isBusiness) return null

  return <TransparencyLaw subject={TransparencyLaw.TransparencyLawSubject.BuyerRights} />
}

export default ItemPageTransparencyLawPlugin
