'use client'

import { Children, ReactNode, useState } from 'react'
import { FormattedDate, useIntl } from 'react-intl'
import { Cell, Text, Divider, Container, Spacer } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

import { ItemPerformanceChartData } from 'types/dtos'
import { ItemPerformanceModel } from 'types/models'

import { DataPoint } from './PerformanceChart/types'
import PerformanceChart from './PerformanceChart'

type Props = {
  itemPromoted: boolean
  performance: ItemPerformanceModel
  initialActivePoint?: number
}

const ItemPerformance = ({ performance, initialActivePoint, itemPromoted = false }: Props) => {
  const intl = useIntl()
  const translate = useTranslate('item_performance')

  const [isDateSelected, setIsDateSelected] = useState(initialActivePoint !== undefined)
  const [activePoint, setActivePoint] = useState<number | undefined>(initialActivePoint)
  const [currentData, setCurrentData] = useState<ItemPerformanceChartData | undefined>(
    initialActivePoint === undefined ? undefined : performance.chartData[initialActivePoint],
  )

  function handleClick() {
    setIsDateSelected(false)
    setActivePoint(undefined)
    setCurrentData(undefined)
  }

  function handlePointClick(_payload: DataPoint | undefined, dataPointIndex: number | undefined) {
    if (dataPointIndex === undefined) return

    setIsDateSelected(true)
    setActivePoint(dataPointIndex)
    setCurrentData(performance.chartData[dataPointIndex])
  }

  function performanceDataToChartData(data: ItemPerformanceChartData) {
    const date = intl.formatDate(new Date(data.date), { day: '2-digit' })

    return {
      value_1: data.impressions,
      value_2: data.highlighted ? data.impressions : null,
      label: date,
    }
  }

  function renderInfo() {
    if (isDateSelected && currentData) {
      const { impressions } = currentData

      return translate(
        'impressions',
        { count: <Text as="span" text={impressions} theme="amplified" key="impressions-count" /> },
        { count: impressions },
      )
    }

    const { impressions } = performance

    const textParts: Array<string | ReactNode> = [
      translate(
        'impressions_over_days',
        {
          count: <Text as="span" text={impressions} theme="amplified" key="impressions-count" />,
          days: (
            <Text
              as="span"
              text={performance.chartData.length}
              theme="amplified"
              key="chart-length"
            />
          ),
        },
        { count: impressions },
      ),
    ]

    if (performance.impressionDifference > 1) {
      textParts.push(
        translate('impression_diff', {
          change: (
            <Text
              as="span"
              text={`${performance.impressionDifference}x`}
              theme="amplified"
              key="impression-difference"
            />
          ),
        }),
      )
    }

    if (performance.recentlyPromoted) {
      if (itemPromoted) {
        textParts.push(translate('bump'))
        textParts.push(`${performance.humanizedHoursLeft}.`)
      } else {
        textParts.push(
          translate(
            'bump_ended_days_ago',
            {
              count: (
                <Text
                  as="span"
                  text={performance.promotionEndedDaysAgo}
                  theme="amplified"
                  key="promotion-end"
                />
              ),
            },
            {
              count: performance.promotionEndedDaysAgo,
            },
          ),
        )
      }
    }

    // Prevents "Each child in an array or iterator should have a unique 'key' prop" warning
    const parts = textParts.reduce(
      (acc: Array<string | ReactNode>, part) => (acc.length ? [...acc, ' ', part] : [part]),
      [],
    )

    return Children.toArray(parts)
  }

  function renderDate() {
    if (isDateSelected && currentData) {
      const date = new Date(currentData.date)

      return (
        <Text as="span" type={Text.Type.Subtitle}>
          <FormattedDate value={date} year="numeric" month="long" day="numeric" />
        </Text>
      )
    }

    return (
      <Text
        as="span"
        type={Text.Type.Subtitle}
        text={translate('subtitle', { days: performance.chartData.length })}
      />
    )
  }

  function renderInteractionsSubtitle() {
    return (
      <Text
        as="span"
        type={Text.Type.Subtitle}
        text={translate('interactions.subtitle', { days: performance.chartData.length })}
      />
    )
  }

  function renderInteractions() {
    return (
      <>
        <Cell
          title={translate('interactions.title')}
          suffix={renderInteractionsSubtitle()}
          styling={Cell.Styling.Tight}
        />
        <Container styling={Container.Styling.Tight}>
          <Text
            as="p"
            text={translate('views', {
              count: (
                <Text as="span" text={performance.views} theme="amplified" key="views-count" />
              ),
            })}
          />
          <br />
          <Text
            as="p"
            text={translate('favourites', {
              count: (
                <Text
                  as="span"
                  text={performance.favorites}
                  theme="amplified"
                  key="favourites-count"
                />
              ),
            })}
          />
          <br />
          <Text
            as="p"
            text={translate(
              'conversations',
              {
                count: (
                  <Text
                    as="span"
                    text={performance.conversations}
                    theme="amplified"
                    key="conversations-count"
                  />
                ),
              },
              { count: performance.conversations },
            )}
          />
        </Container>
      </>
    )
  }

  return (
    <Cell styling={Cell.Styling.Tight} onClick={handleClick} testId="bump-statistic">
      <Container>
        <Cell title={translate('title')} suffix={renderDate()} styling={Cell.Styling.Tight} />
        <Spacer size={Spacer.Size.Large} />
        <PerformanceChart
          data={performance.chartData.map(performanceDataToChartData)}
          activePoint={activePoint}
          onPointClick={handlePointClick}
          interactive
        />
        <Spacer size={Spacer.Size.Large} />
        <Text as="p">{renderInfo()}</Text>
      </Container>
      <Divider />
      <Container> {renderInteractions()}</Container>
    </Cell>
  )
}

export default ItemPerformance
