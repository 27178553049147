'use client'

import { useMemo, useRef } from 'react'
import { InView } from 'react-intersection-observer'

import { ViewAllUserItemsLink } from '@marketplace-web/domain/profile'
import { useFetch } from '@marketplace-web/shared/api-client'
import { ContentLoader } from '@marketplace-web/shared/ui-helpers'
import { ContentSource } from 'constants/tracking/content-sources'
import { getItemsByPluginName } from 'data/api'

import ItemViewItems from '../../components/ItemViewItems/ItemViewItems'
import { transformItemsBlockItemDtoToProductItem } from '../ItemsBlock/transformers'
import { ItemPageClosetPluginModel } from './types'

type Props = {
  data: ItemPageClosetPluginModel
}

const ItemPageClosetPlugin = ({ data }: Props) => {
  const { itemId, itemUserId } = data

  const { fetch: fetchUserItems, data: userItemsData, isLoading } = useFetch(getItemsByPluginName)
  const hasFetched = useRef(false)

  const userItems = useMemo(() => {
    return userItemsData?.items.filter(item => item.id !== itemId) || []
  }, [userItemsData, itemId])

  const onInViewChange = (inView: boolean) => {
    if (!inView || hasFetched.current) return

    fetchUserItems({
      itemId: itemId.toString(),
      name: 'other_user_items',
    })
    hasFetched.current = true
  }

  return (
    <InView as="div" data-testid="item-page-closet-plugin" onChange={onInViewChange}>
      {isLoading && (
        <div className="u-ui-margin-top-medium">
          <ContentLoader testId="item-feed-loader" />
        </div>
      )}
      {userItems?.length > 0 && (
        <>
          <ItemViewItems
            items={userItems.map(transformItemsBlockItemDtoToProductItem)}
            contentSource={ContentSource.OtherUserItems}
            itemTestId="owner-item"
          />
          {!!userItemsData?.pagination.total_entries && (
            <ViewAllUserItemsLink
              ownerItemCount={userItemsData.pagination.total_entries}
              ownerId={itemUserId}
              itemId={itemId.toString()}
            />
          )}
        </>
      )}
    </InView>
  )
}

export default ItemPageClosetPlugin
