'use client'

import { ReactNode, useContext, useMemo } from 'react'
import classNames from 'classnames'

import Toggleable from 'components/Toggleable'
import { ToggleableContext } from 'components/Toggleable/ToggleableContext'

type Alignment = 'left' | 'right'

type Props = {
  children: ReactNode
  isWide?: boolean
  isRounded?: boolean
  noShadow?: boolean
  alignment?: Alignment
  forceVisibility?: boolean
}

const DropdownMenu = ({
  children,
  isWide,
  isRounded,
  forceVisibility,
  alignment = 'right',
  noShadow,
}: Props) => {
  const { isVisible } = useContext(ToggleableContext)

  const isHidden = !forceVisibility && !isVisible

  const shouldAlignLeft = useMemo(() => {
    if (isHidden) return false

    return alignment === 'left'
  }, [isHidden, alignment])

  const classes = classNames('dropdown-menu', {
    'dropdown-menu--shadow': !noShadow,
    'dropdown-wide dropdown--fixed-width': isWide,
    'dropdown-menu--rounded': isRounded,
    'dropdown-menu--align-left': shouldAlignLeft,
  })

  if (isHidden) return null

  return <Toggleable.Menu>{() => <div className={classes}>{children}</div>}</Toggleable.Menu>
}

export default DropdownMenu
