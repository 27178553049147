'use client'

import classNames from 'classnames'
import { Cell, Spacer, Text, Icon } from '@vinted/web-ui'
import { AuthenticityDiamondFilled16 } from '@vinted/monochrome-icons'
import { AuthenticityDiamondMultichome16Dark } from '@vinted/multichrome-icons'

import { useIsDarkModeEnabledFromCookies } from '@marketplace-web/shared/dark-mode'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { ItemPageOfflineVerificationStatusPluginModel } from './types'

type Props = {
  data: ItemPageOfflineVerificationStatusPluginModel
}

const ItemPageOfflineVerificationStatusPlugin = ({ data }: Props) => {
  const translate = useTranslate('item.status')
  const isDarkModeEnabled = useIsDarkModeEnabledFromCookies()

  if (!data?.offlineVerification) {
    return null
  }

  const iconName = isDarkModeEnabled
    ? AuthenticityDiamondMultichome16Dark
    : AuthenticityDiamondFilled16

  return (
    <div
      className={classNames('offline-verification-status', {
        'offline-verification-status--dark': isDarkModeEnabled,
      })}
    >
      <Cell
        styling={Cell.Styling.Narrow}
        theme="transparent"
        testId="item-offline-verification-block-status"
      >
        <div className="u-flexbox u-align-items-center">
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Icon
            name={iconName}
            color={Icon.Color.Primary}
            aria={{
              'aria-hidden': 'true',
            }}
          />
          <Spacer orientation={Spacer.Orientation.Vertical} />
          <Text
            as="h4"
            text={translate('physical_authenticity')}
            type={Text.Type.Caption}
            theme="amplified"
          />
        </div>
      </Cell>
    </div>
  )
}

export default ItemPageOfflineVerificationStatusPlugin
