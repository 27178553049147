import { ItemDetailsDto } from 'types/dtos/item-details'

import { ItemPageDeleteActionModel } from '../types'

export const getItemPageDeleteActionPluginModel = (
  itemDto: ItemDetailsDto,
): ItemPageDeleteActionModel => ({
  id: itemDto.id,
  userId: itemDto.user.id,
  canDelete: itemDto.can_delete,
  isClosed: itemDto.is_closed,
})
