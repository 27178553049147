import { ItemPageDescriptionDto, ItemPageDescriptionPluginModel } from './types'

export const getItemPageDescriptionPluginModel = (
  descriptionPluginDto: ItemPageDescriptionDto,
): ItemPageDescriptionPluginModel => ({
  itemId: descriptionPluginDto.item_id,
  localization: descriptionPluginDto.localization,
  description: descriptionPluginDto.description,
  isLocalized: descriptionPluginDto.is_localized,
  maxLines: descriptionPluginDto.max_lines,
})
